<template>
  <div class="ctk-address-accessibility tw-py-3 tw-pt-1 tw-px-2 tw-border tw-border-gray-300 tw-border-solid tw-rounded tw-bg-white">
    <div
      class="tw-flex tw-items-center tw-text-red-500 tw-mb-px tw-mr-4 ctk-address-accessibility__header"
      data-test="header"
    >
      <ui-ctk-icon
        class="tw--ml-1 tw-text-3xl"
        name="warning"
        data-test="icon"
      />
      <span
        v-text="$t('app.labels.accessibility_issue')"
        data-test="label"
        class="tw-text-sm tw-mt-px tw-whitespace-nowrap"
      />
    </div>
    <div
      class="tw-flex ctk-address-accessibility__items"
      data-test="items"
    >
      <div
        v-for="item in items"
        :key="item.key"
        class="tw-flex tw-flex-col tw-items-center tw-rounded tw-mr-3 tw-px-8 ctk-address-accessibility__items__item"
        data-test="item"
      >
        <div
          class="tw-mt-px tw-relative ctk-address-accessibility__items__item__truck"
        >
          <component :is="item.icon" />
        </div>

        <div
          v-text="item.label"
          class="tw-text-xs tw--mt-px tw-text-gray-600"
          data-test="label"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import Hotjar from '@/plugins/VueHotjar'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'
  import SPLTruck from '@/views/Carriers/Offers/components/SearchSideBar/_subs/SearchForm/_subs/PredefinedDimensions/_subs/Dimension/SPLTruck/index.vue'
  import PLTruck from '@/views/Carriers/Offers/components/SearchSideBar/_subs/SearchForm/_subs/PredefinedDimensions/_subs/Dimension/PLTruck/index.vue'

  /**
   * @module component - CtkAddressAccessibility
   * @param {string} vehicle_type
   */
  export default defineComponent({
    name: 'CtkAddressAccessibility',
    components: {
      SPLTruck,
      PLTruck,
      UiCtkIcon
    },
    props: {
      vehicleType: {
        type: String,
        required: true
      }
    },
    mounted () {
      Hotjar.tag('Offer With Complex Address')
    },
    computed: {
      /**
       * @function items
       * @returns {Array<any>}
       */
      items () {
        let items = [
          {
            key: 'standard_trailer',
            label: this.$t('app.labels.accessibility.standard_trailer'),
            icon: 'SPLTruck'
          }
        ]

        if (this.vehicleType === 'lightweight_vehicle') {
          items = [
            {
              key: 'carrier_truck',
              label: this.$t('app.labels.accessibility.carrier_truck'),
              icon: 'PLTruck'
            },
            ...items
          ]
        }

        return items
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-address-accessibility {
    &__items__item {
      background-color: #E9E9EA;

      &__truck {
        &::before,
        &::after {
          position: absolute;
          content: '';
          width: 22px;
          height: 2px;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: $danger;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .ctk-address-accessibility .ctk-address-accessibility__items__item__truck svg {
    max-width: 35px;

    path {
      fill: $secondary-text;
    }
  }

</style>
