<template>
  <section class="tab-info-references">
    <h5
      class="tab-info-references__title tw-font-light"
      data-test="title"
    >
      {{ $tc('missions.mission_detail.label.reference', 2) | capitalize }}
    </h5>

    <pricing-layout class="tw-mb-3 tw-p-0">
      <div
        v-for="reference in references"
        :key="reference.key"
        :data-test="`reference-${reference.key}`"
        class="tab-info-references__item tw-p-2"
      >
        <div data-test="label">
          {{ $t(`missions.mission_detail.label.${reference.key}`) }}
        </div>
        <div
          class="tw-text-blue-500 tw-break-words"
          data-test="value"
        >
          {{ reference.ref }}
        </div>
      </div>
    </pricing-layout>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout'

  /**
   * @module component - TabInfoReferences
   */
  export default {
    name: 'TabInfoReferences',
    components: {
      PricingLayout
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission'
      ]),
      /**
       * @function references
       * @returns {Array}
       */
      references () {
        const shipment = this.getCurrentMission.shipment
        const { shipper_reference: shipperReference } = shipment
        return [
          { ref: shipment.reference, key: 'ctk' },
          ...(shipperReference ? [{ ref: shipperReference, key: 'shipper_reference' }] : [])
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

  .tab-info-references {
    &__item:not(:last-child) {
      border-bottom: 1px solid #F2F2F2;
    }
  }

</style>

<style lang="scss">

  .tab-info-references .pricing-layout {
    padding: 0 !important;
  }

</style>
