<template>
  <div class="mission-item-dates tw-flex">
    <div class="mission-item-dates__date tw-flex tw-flex-col dots-text">
      <ctk-timeslot-date
        v-for="direction in ['pickup', 'delivery']"
        :key="direction"
        :flexible="mission[direction].time_slot.flexible"
        :direction="direction"
        class="dots-text"
      >
        {{ date(direction) }}
      </ctk-timeslot-date>
    </div>
    <div class="mission-item-dates__hour tw-flex-1 dots-text">
      <div
        v-for="direction in ['pickup', 'delivery']"
        :key="direction"
        class="dots-text"
        data-test="hour"
      >
        {{ hours(direction) }}
      </div>
    </div>
  </div>
</template>

<script>
  import CtkTimeslotDate from '@/components/CtkTimeslotDate'

  /**
   * @module component - MissionItemDates
   * @param {object} mission
   */
  export default {
    name: 'MissionItemDates',
    components: {
      CtkTimeslotDate
    },
    props: {
      mission: {
        type: Object,
        default: null
      }
    },
    methods: {
      /**
       * Returns the formatted date
       * @function date
       * @param {string} direction
       * @returns {string}
       */
      date (direction) {
        const { date } = this.mission[direction].time_slot
        return this.$moment(date).format('LL')
      },
      /**
       * Returns the translation key with the formatted hours
       * @function hours
       * @param {string} direction
       * @returns {string}
       */
      hours (direction) {
        const {
          start_time: startTime,
          end_time: endTime
        } = this.mission[direction].time_slot

        const start = this.$moment(startTime, 'HH:mm').format('LT')
        const end = this.$moment(endTime, 'HH:mm').format('LT')

        return this.$t('missions.values.date_ranges', {
          start,
          end
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .mission-item-dates {
    &__date,
    &__hour {
      position: relative;
      color: $secondary-text;
      padding-left: 22px;

      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 22px;
        top: 10px;
        left: 8px;
        border: 1px solid $third-text;
        border-right: none;
      }

      @media only screen and (max-width: $breakpoint-mobile-s) {
        overflow: visible;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        left: -8px;
      }
    }

    &__date {
      flex: 0.6;
    }
  }

</style>
