<template>
  <div
    class="proof-of-delivery-view tw-relative tw-flex-1 tw-overflow-hidden tw-flex tw-flex-col tw-text-base tw-bg-white tw-rounded tw-justify-center"
  >
    <div
      class="tw-text-green-500 tw-flex tw-items-center tw-justify-center tw-mb-4"
      data-test="title"
    >
      <ui-material-icon
        class="tw-mr-2"
        name="check"
        data-test="icon"
      />{{ $t('missions.titles.proof_of_delivery_sent') }}
    </div>

    <div class="tw-flex tw-items-center tw-px-4 tw-mb-4">
      <div
        class="tw-w-1/2 tw-mr-4 tw-flex"
      >
        <ctk-btn-file
          id="btn-download-proof-of-delivery"
          :url="downloadFileUrl"
          :popover-title="$t('missions.proof_of_delivery_title')"
          :file-name="`mission-order-${getCurrentMission.reference}.pdf`"
          class="tw-flex-1"
          data-test="download"
        >
          <ui-button
            variant="info"
            outline
            block
          >
            {{ $t('missions.preview') }}
          </ui-button>
        </ctk-btn-file>
      </div>

      <div class="tw-w-1/2 tw-flex tw-justify-center">
        <ui-button
          variant="info"
          outline
          block
          data-test="edit"
          @click="reupload"
        >
          {{ $t('missions.modify') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - proofOfDeliveryView
   */
  export default {
    name: 'ProofOfDeliveryView',
    components: {
      CtkBtnFile
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionUuid',
        'getCurrentMission'
      ]),
      ...mapGetters('auth', ['getCid']),
      /**
       * @function downloadFileUrl
       */
      downloadFileUrl () {
        return `v2/companies/${this.getCid}/missions/${this.getCurrentMissionUuid}/proof-of-delivery`
      }
    },
    methods: {
      ...mapActions('missions', [
        'updateProofOfDelivery'
      ]),
      /**
       * @function reupload
       */
      reupload () {
        this.updateProofOfDelivery({
          value: {
            uuid: this.getCurrentMissionUuid,
            status: 'pending'
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .proof-of-delivery-view {
    height: 200px;

    &::before {
      content: '';
      height: 44px;
      width: 252px;
      right: 0;
      bottom: 0;
      background-image: url('~@/assets/img/illustration_search.svg');
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: contain;
      opacity: 0.7;
      margin: 0 auto;
      position: absolute;
      z-index: 1;
    }
  }

</style>
