<template>
  <ctk-aside-dialog
    v-model="modalState"
    modal-class="change-driver-dialog"
    :title="$t('missions.reallocation_driver')"
  >
    <div
      class="tw-p-4 md:tw-px-10 md:tw-pb-10 md:tw-pt-28 tw-h-full tw-flex tw-flex-col"
    >
      <tabs-bar
        v-model="currentTab"
        :tabs="tabs"
        class="tw-mb-6"
        data-test="tabs"
      />

      <template
        v-if="currentTab === 0"
      >
        <ctk-choose-driver-form
          :drivers-count="driversCount"
          :drivers="drivers"
          class="tw-w-full tw-flex-1"
          @validate="changeDriver"
          @cancel="close"
          @close="close"
        >
          <template #text-button>
            <span>
              {{ $t('missions.reallocation_to_this_driver') | capitalize }}
            </span>
          </template>
        </ctk-choose-driver-form>
      </template>
      <template
        v-else
      >
        <ctk-add-driver-form
          :error-message="errorMessage"
          :has-back-button="true"
          :text-button="$t('missions.add_driver_and_reallocation') | capitalize"
          class="tw-w-full tw-flex-1"
          @close="close"
          @back="close"
          @save-driver="saveDriver"
        />
      </template>
    </div>
  </ctk-aside-dialog>
</template>

<script>
  import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkAsideDialog from '@/components/CtkAsideDialog/index.vue'
  import CtkAddDriverForm from '@/components/CtkAddDriverForm/index.vue'
  import CtkChooseDriverForm from '@/components/CtkChooseDriverForm/index.vue'
  import { TabsBar } from '@/components/CtkTabsLayout'

  import useStore from '@/composables/useStore'
  import useWait from '@/composables/useWait'

  import { Driver as DriverResource, CompanyMission } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import Driver from '@/models/Driver'
  import { i18n } from '@/locales'

  /**
   * @module component - ChangeDriverDialog
   */
  export default defineComponent({
    name: 'ChangeDriverDialog',
    components: {
      CtkAsideDialog,
      CtkAddDriverForm,
      CtkChooseDriverForm,
      TabsBar
    },
    emits: [
      'value'
    ],
    model: {
      prop: 'value'
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')
      const store = useStore()
      const wait = useWait()

      const currentTab = ref(0)
      /**
       * @type {import('@vue/composition-api').Ref<Array<{ uuid: string }>>}
       */
      const drivers = ref([])
      const driversCount = ref(null)
      const errorMessage = ref(null)

      /**
       * @function tabs
       * @returns {Array<{label: any}>}
       */
      const tabs = computed(() => {
        return [
          { label: i18n.t('app.labels.drivers_available') },
          { label: i18n.t('app.labels.add_driver') }
        ]
      })

      function close () {
        modalState.value = false
      }

      function getAllDrivers () {
        wait.start('fetching drivers for booking')

        let page = 1
        drivers.value = []

        const retrieveDrivers = () => {
          DriverResource.get({
            cid: store.value.getters['auth/getCid']
          }, {
            limit: 30,
            page
          })
            .then(response => {
              driversCount.value = response.data.meta.item_count
              if (driversCount.value === 0) {
                currentTab.value = 1
              }

              const pagination = response.data.meta.pagination
              if (pagination.current_page < pagination.page_count) {
                page += 1
                retrieveDrivers()
              } else {
                wait.end('fetching drivers for booking')
              }

              response.data.items
                // @ts-ignore
                .map(driver => new Driver(driver))
                // @ts-ignore
                .forEach((driver) => {
                  drivers.value.push(driver)
                })
            }, error => {
              console.log('error get all drivers', error)
            })
        }
        retrieveDrivers()
      }

      /**
       * @function changeDriver
       * @param {{ uuid: string }} driver
       */
      function changeDriver (driver) {
        wait.start('updating mission driver')
        const missionUuid = store.value.getters['missions/getCurrentMission'].uuid
        CompanyMission.changeDriver({
          cid: store.value.getters['auth/getCid'],
          mid: missionUuid
        }, { driver: driver.uuid, notify_shipper: false })
          .then(
            () => {
              store.value.dispatch('missions/updateCurrentMissionDriver', { driver })
              modalState.value = false
            }, (err) => {
              const data = err.response && err.response.data
              if (data && data.error) {
                const errorMessage = data.error.detail || data.error.title
                showToaster(null, errorMessage, {
                  type: 'error',
                  position: 'bottom-right'
                })
              }
            }
          )
          .finally(() => {
            wait.end('updating mission driver')
          })
      }

      /**
       * @function saveDriver
       * @param {object} driverData
       */
      function saveDriver (driverData) {
        wait.start('creating the driver')

        errorMessage.value = null
        DriverResource.save({
          cid: store.value.getters['auth/getCid']
        }, driverData)
          .then(({ data }) => {
            const driver = {
              ...driverData,
              ...data
            }
            changeDriver(driver)
          })
          .catch((err) => {
            const data = err.response && err.response.data
            if (data && data.error) {
              /**
               * Show the violation message in the input
               */
              if (data.error.violations) {
                // @ts-ignore
                data.error.violations.map((violation) => {
                  switch (violation.property_path) {
                  case 'phone':
                    errorMessage.value = violation.message
                    break
                  }
                })
              }

              const errorMessage = data.error.detail || data.error.title
              showToaster(null, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            wait.end('creating the driver')
          })
      }

      function initializeDialog () {
        /**
         * Reset the values to their initial value.
         */
        currentTab.value = 0

        getAllDrivers()
      }

      onMounted(() => {
        initializeDialog()
      })

      watch(modalState, v => {
        if (v) {
          initializeDialog()
        }
      })

      return {
        getAllDrivers,
        modalState,
        currentTab,
        tabs,
        close,
        drivers,
        driversCount,
        saveDriver,
        changeDriver,
        errorMessage
      }
    }
  })
</script>

<style lang="scss">
.change-driver-dialog .modal-container {
  height: clamp(300px, 743px, 100%);
}
.change-driver-dialog .ctk-aside-dialog__main, .change-driver-dialog .ctk-aside-dialog__body, .change-driver-dialog .modal-body {
  height: 100%;
}
.change-driver-dialog .choose-driver-form {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
}
.change-driver-dialog .choose-driver-form__footer {
  margin-top: 0px;
}
.change-driver-dialog .choose-driver-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  overflow-y: auto;
  height: calc(100% - 55px);
}
@media (min-width: 770px) {
  .change-driver-dialog .choose-driver-form__footer, .change-driver-dialog .add-driver-form__footer {
    position: relative;
  }
  .change-driver-dialog .choose-driver-form__footer::before, .change-driver-dialog .add-driver-form__footer::before {
    position: absolute;
    content: '';
    left: -2.5rem;
    top: 0;
    width: calc(100% + 5rem);
    height: 10px;
    box-shadow: 0 -2px 2px 0 rgba($secondary-text, 0.25);
  }
}
</style>
