<template>
  <div class="mission-sections tw-flex-1 tw-overflow-y-auto">
    <ctk-collapse-category-item
      :title="$options.filters.capitalize($t('navigation.missions'))"
      :icon="'marker'"
      data-test="category"
    />
    <ctk-collapse-nav-item
      v-for="(state, k) in states"
      :key="k"
      :to="state.to"
      :title="state.title | capitalize"
      :count="state.count"
      :has-dot="state.hasDot"
      :dot-tooltip="state.dotTooltip"
      class="mission-sections__item"
      @click.native="closeSidebar"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  import CtkCollapseNavItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseNavItem/index.vue'
  import CtkCollapseCategoryItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseCategoryItem/index.vue'

  /**
   * @module component - MissionSections
   */
  export default defineComponent({
    name: 'MissionSections',
    components: {
      CtkCollapseNavItem,
      CtkCollapseCategoryItem
    },
    computed: {
      ...mapGetters('missions', [
        'getCompanyMetrics',
        'getCompanyMetricsCount'
      ]),
      /**
       * @function states
       * @returns {Array<any>}
       */
      states () {
        const {
          late_pickup: latePickup,
          late_delivery: lateDelivery
        } = this.getCompanyMetrics
        const hasLate = latePickup > 0 || lateDelivery > 0
        const lateCount = Math.max(latePickup, lateDelivery)

        return ['planned', 'in_progress', 'completed', 'cancelled']
          .map(state => ({
            key: state,
            title: this.$tc(`missions.state.${state}`, 2),
            count: this.getCompanyMetrics[state],
            hasDot: state === 'in_progress' && hasLate,
            dotTooltip: hasLate
              ? this.$tc('missions.values.late_tracking_tooltip', lateCount, {
                count: this.$n(lateCount)
              })
              : null,
            to: {
              name: 'Missions',
              params: {
                state
              }
            }
          }))
      }
    },
    methods: {
      ...mapActions('missions', [
        'resetCurrentMission'
      ]),
      /**
       * Closes the mission left sidebar if the viewport is too thin
       * @function closeSidebar
       */
      closeSidebar () {
        this.resetCurrentMission()

        const width = document.documentElement.clientWidth
        if (width <= 768) {
          EventBus.$emit('missions:sidebar-visible', false)
        }
      }
    }
  })
</script>

<style lang="scss">

  .mission-sections {
    padding: 30px 28px;

    .ctk-collapse-nav-item {
      padding-left: 30px;

      &__badge__icon {
        background-color: $warning !important;
      }
    }
  }

</style>
