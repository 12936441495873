<template>
  <div class="follow-up-status mb-4">
    <div
      :class="{'late': isLate, 'cancelled': statusCode === 'cancelled' }"
      class="follow-up-status-container tw-rounded"
    >
      <div class="follow-up-status-container-content pt-4 tw-flex tw-items-center tw-justify-center tw-flex-col">
        <i18n
          v-if="statusCode === 'planned' && !isLate"
          :path="timeLeftBeforePickup.key"
          tag="span"
        >
          <template #time>
            <span
              class="tw-text-blue-500 tw-font-medium"
            >
              {{ timeLeftBeforePickup.time }}
            </span>
          </template>
        </i18n>
        <span
          v-else-if="statusCode === 'pod_completed'"
          class="tw-text-blue-500 tw-text-center confirmation-text"
          v-markdown="$t('missions.mission_completed')"
        />
        <UiBadge
          v-else
          :color="isLate
            ? 'pink'
            : statusCode === 'cancelled'
              ? 'red'
              : 'blue'"
          :neutral="!isLate && statusCode !== 'cancelled'"
        >
          {{ title | capitalize }}
        </UiBadge>
        <img
          :src="illustration"
          class="illustration mt-2 tw-w-full tw-max-w-full"
        >
        <div
          v-if="statusCode !== 'planned'"
          :class="[{'move': enableTruck}, statusCode]"
          class="follow-up-status-container-content-truck"
        >
          <img
            src="./assets/truck.svg"
            alt=""
            width="42"
            height="15"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import IlluCompleted from './assets/illustration_completed.png'
  import IlluInProgressBLPending from './assets/illustration_in_progress_bl_pending.png'
  import IlluInProgressDeliveryPending from './assets/illustration_in_progress_delivery_pending.png'
  import IlluInProgressPickupPending from './assets/illustration_in_progress_pickup_pending.png'
  import IlluCancelled from './assets/illustration_cancelled.png'
  import IlluPlanned from './assets/illustration_planned.png'
  import timeLeftFromNow from '@/services/TimeLeftFromNow'

  /**
   * @module component - followUpStatus
   * @param {object} mission
   */
  export default defineComponent({
    name: 'FollowUpStatus',
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        enableTruck: false
      }
    },
    computed: {
      title () {
        return this.isCmrPending && this.hasLateDelivery
          ? this.$t('missions.tracking_time_limit_expired')
          : this.isLate
            ? this.$t(`missions.mission_item.late_text.${this.lateDirection}`)
            : this.status
      },
      illustration () {
        let value
        switch (this.statusCode) {
        case 'cancelled':
          value = IlluCancelled
          break
        case 'planned':
          value = IlluPlanned
          break
        case 'pickup_pending':
          value = IlluInProgressPickupPending
          break
        case 'delivery_pending': case 'billing_pending':
          value = IlluInProgressDeliveryPending
          break
        case 'pod_pending': case 'tracking_expired':
          value = IlluInProgressBLPending
          break
        case 'pod_completed':
          value = IlluCompleted
          break
        default:
          value = IlluPlanned
          break
        }
        return value
      },
      isCmrPending () {
        return this.mission.proof_of_delivery && this.mission.proof_of_delivery.state === 'pending'
      },
      hasLateDelivery () {
        return this.mission.delivery.state === 'late'
      },
      /**
       * @function isLate
       */
      isLate () {
        return (this.mission.pickup.state === 'late' || this.mission.delivery.state === 'late')
      },
      /**
       * @function lateDirection
       */
      lateDirection () {
        return this.mission.pickup.state === 'late' ? 'pickup' : 'delivery'
      },
      /**
       * @function statusCode
       * @returns {string} code
       */
      statusCode () {
        const pickupState = this.mission.pickup.state
        const deliveryState = this.mission.delivery.state
        const podState = this.mission.proof_of_delivery ? this.mission.proof_of_delivery.state : null
        if (this.isCmrPending && this.hasLateDelivery) {
          return 'tracking_expired'
        } else if (this.mission.state === 'cancelled') {
          return 'cancelled'
        } else if (this.mission.state === 'planned') {
          /**
           * Mission didn't started yet
           */
          return 'planned'
        } else if (this.mission.state === 'in_progress' && deliveryState !== 'completed') {
          /**
           * Mission started, in the pickup / delivery steps.
           */
          return pickupState === 'pending' || pickupState === 'late'
            ? 'pickup_pending'
            : 'delivery_pending'
        } else if (this.mission.proof_of_delivery && this.mission.proof_of_delivery.state === 'pending') {
          /**
           * Delivered, waiting for proof of delivery.
           */
          return 'pod_pending'
        } else if (pickupState === 'completed' && deliveryState === 'completed' && podState !== 'pending' && this.mission.state !== 'completed') {
          return 'billing_pending'
        } else if (this.mission.state === 'completed') {
          return 'pod_completed'
        } else {
          return null
        }
      },
      /**
       * @function status
       */
      status () {
        const codes = {
          pickup_pending: this.$t('missions.mission_item.late_text.pickup_pending'),
          delivery_pending: this.$t('missions.mission_item.late_text.delivery_pending'),
          pod_pending: this.$t('missions.mission_item.late_text.pod_pending'),
          billing_pending: this.$t('missions.mission_item.late_text.billing_pending'),
          pod_completed: this.$t('missions.titles.proof_of_delivery_sent'),
          cancelled: this.$t('missions.mission_cancelled')
        }

        return codes[this.statusCode]
      },
      timeLeftBeforePickup () {
        const { date, start_time: startTime } = this.mission.pickup.time_slot
        const { isInPast, fromNow } = timeLeftFromNow(date, startTime)
        return {
          key: isInPast ? 'missions.pickup_begin_in_x_time_passed' : 'missions.pickup_begin_in_x_time',
          time: fromNow
        }
      }
    },
    mounted () {
      setTimeout(() => {
        this.enableTruck = true
      }, 1000)
    }
  })
</script>

<style lang="scss">

  .follow-up-status .confirmation-text.markdown {
    p {
      margin-bottom: 0;
    }

    strong {
      display: block;
      font-weight: normal;
    }
  }

</style>

<style lang="scss" scoped>

  .follow-up-status {
    &-container {
      background-color: white;
      overflow: hidden;

      &-header {
        .icon-ctk-warning {
          font-size: 32px;
          vertical-align: middle;
          color: $danger;
        }
      }

      &-content {
        position: relative;

        .chip {
          line-height: 15px;
          background-color: $info;
          position: relative;
          color: white;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        }

        img.illustration {
          margin-bottom: -3px;
          min-height: 46px;
        }

        &-truck {
          position: absolute;
          right: 0;
          bottom: -1px;
          transition: 0.8s all cubic-bezier(0.25, 0.8, 0.5, 1);
          transform: translateX(-380px);

          &.move {
            transform: translateX(-300px);

            &.delivery_pending {
              transform: translateX(-140px);
            }

            &.tracking_expired,
            &.billing_pending,
            &.pod_completed,
            &.pod_pending {
              transform: translateX(-50px);
            }

            &.cancelled {
              transform: translateX(-380px);
            }
          }
        }

        .confirmation-text {
          line-height: 15px;
        }
      }

      &.cancelled {
        .chip {
          background-color: $danger !important;
        }
      }
    }
  }

</style>
