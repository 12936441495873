<template>
  <aside
    class="mission-detail tw-w-full tw-flex-1 tw-flex tw-flex-col"
  >
    <header
      class="mission-detail__header tw-bg-white tw-relative tw-shadow-md tw-z-50 flex-fixed"
      data-test="header"
    >
      <mission-detail-header />
      <tabs-bar
        v-model="currentTab"
        :tabs="tabs"
      />
    </header>

    <div
      ref="missionDetailContent"
      class="mission-detail__content tw-relative tw-z-10 tw-flex-1 tw-flex tw-flex-col"
      data-test="content"
    >
      <div
        v-if="hasMap"
        class="mission-detail__content__map tw-top-0 tw-left-0 tw-w-full tw-z-0 tw-relative flex-fixed"
      >
        <call-center-btn />
        <mission-detail-map
          ref="map"
          :mission="getCurrentMission"
          :direction-focus="currentTab === 1 ? 'marker' : 'travel'"
          :height="heightMap"
        />
      </div>

      <tabs-content
        :class="{
          'animated bounce': currentTab === 0 && getCurrentMissionState === 'planned'
        }"
        class="flex-fixed"
        :current-tab="currentTab"
      >
        <tabs-content-item
          v-show="currentTab === 0"
          :class="animationTab"
          :is-current="currentTab === 0"
          class="tab-info animated"
        >
          <tab-info
            v-if="currentTab === 0"
            @hook:mounted="checkHeight"
          />
        </tabs-content-item>
        <tabs-content-item
          v-show="currentTab === 1"
          :class="[animationTab, {'tab-steps': !hasDeliveryLate && !showCmr}]"
          :is-current="currentTab === 1"
          :no-shadow="showCmr"
          class="animated"
        >
          <tab-cmr
            v-if="showCmr"
            @hook:mounted="checkHeight"
          />
          <tab-steps
            v-else
            @hook:mounted="checkHeight(true)"
          />
        </tabs-content-item>
        <tabs-content-item
          v-show="currentTab === 2"
          :class="animationTab"
          :is-current="currentTab === 2"
          class="tab-billing animated"
          no-shadow
        >
          <tab-billing
            :mission="getCurrentMission"
            @hook:mounted="checkHeight"
          />
        </tabs-content-item>
      </tabs-content>
    </div>
  </aside>
</template>

<script>
  import { TabsBar, TabsContent, TabsContentItem } from '@/components/CtkTabsLayout'
  import MissionDetailHeader from './_subs/MissionDetailHeader'
  import CallCenterBtn from '@/views/Carriers/Missions/components/CallCenterBtn'
  import TabInfo from './_subs/TabInfo'
  import TabSteps from './_subs/TabSteps'
  import TabBilling from './_subs/TabBilling'
  import TabCmr from './_subs/TabCmr'
  import { mapGetters } from 'vuex'
  import MissionDetailMap from './_subs/MissionDetailMap'
  import { EventBus } from '@/services/EventBus'

  export default {
    name: 'MissionDetail',
    components: {
      TabsBar,
      TabsContent,
      TabsContentItem,
      MissionDetailHeader,
      CallCenterBtn,
      TabInfo,
      TabSteps,
      TabBilling,
      TabCmr,
      MissionDetailMap
    },
    data () {
      return {
        heightMap: null,
        currentTab: 0,
        animationTab: 'slideInRight'
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission',
        'getCurrentMissionState'
      ]),
      /**
       * Returns true if the user should have the map, according to the current tabs & other
       * misc parameters.
       * @function hasMap
       * @returns {boolean}
       */
      hasMap () {
        const isInformationTab = this.currentTab === 0
        const isStepsTab = this.currentTab === 1 && !this.hasDeliveryLate && !this.showCmr
        return (isInformationTab || isStepsTab) && this.heightMap
      },
      showCmr () {
        return ((this.hasDeliveryLate || this.getCurrentMission.delivery.state === 'completed') || this.getCurrentMissionState === 'completed')
      },
      /**
       * Returns true if the mission is late, and the expiration date is less than now + 1 day
       * @function hasDeliveryDate
       * @returns {boolean}
       */
      hasDeliveryLate () {
        const { delivery } = this.getCurrentMission
        const now = this.$moment()
        const deliveryExpirationDate = this.$moment(`${delivery.time_slot.date} ${delivery.time_slot.end_time}`).add(24, 'hours')

        return delivery.state === 'late' &&
          now >= deliveryExpirationDate
      },
      tabs () {
        return [
          { label: this.$t('missions.detail.tab.infos') },
          {
            label: this.$t(this.showCmr
              ? 'missions.detail.tab.cmr'
              : 'missions.detail.tab.steps'),
            active: !this.isCancelled
          },
          { label: this.$t('missions.detail.tab.billing') }
        ]
      },
      isCancelled () {
        return this.getCurrentMissionState === 'cancelled'
      }
    },
    watch: {
      'getCurrentMission.uuid' () {
        this.currentTab = this.selectGoodTab()
      },
      currentTab (newVal, oldVal) {
        this.animationTab = newVal > oldVal ? 'slideInRight' : 'slideInLeft'
        /**
         * Force the map height to be updated whenever the user switches from tabs
         */
        this.checkHeight()
      }
    },
    mounted () {
      this.currentTab = this.selectGoodTab()

      this.$nextTick(() => {
        this.checkHeight()
      })

      EventBus.$on('update-mission-detail-current-tab', (val) => {
        this.currentTab = val
      })
    },
    beforeDestroy () {
      EventBus.$off('update-mission-detail-current-tab')
    },
    methods: {
      /**
       * Recursively check the container height to adapt the map height
       * @function checkHeight
       */
      checkHeight (timeout) {
        if (timeout) {
          setTimeout(this.checkHeight, 200)
        }

        const height = this.$refs.missionDetailContent.clientHeight
        if (height === 0) {
          /**
           * Due to IE11 issues, height is zero from a certain period. Wait a certain
           * amount of time before trying to get the content height.
           */
          setTimeout(() => {
            this.checkHeight()
          }, 200)
        } else {
          this.heightMap = height - 235 + 'px'
        }
      },
      selectGoodTab () {
        const waitingBill = this.getCurrentMissionState !== 'completed' && this.getCurrentMissionState === 'in_progress' && (this.getCurrentMission.proof_of_delivery && this.getCurrentMission.proof_of_delivery.state === 'available')
        switch (waitingBill || this.getCurrentMissionState) {
        case 'planned':
          return 0
        case 'in_progress':
          return 1
        default:
          return 2
        }
      }
    }
  }
</script>

<style lang="scss">
.mission-detail .tabs-bar-item {
  width: 100%;
}
.mission-detail {
  background-color: #F2F2F2;
}
.mission-detail .animated.bounce {
  animation-iteration-count: 2;
}
.mission-detail__content__map .mission-detail-map {
  min-height: 200px;
}
.mission-detail__content {
  overflow-x: auto;
}
.mission-detail__content .tab-info {
  background: #F2F2F2;
}
.mission-detail__content .tab-steps, .mission-detail__content .tab-info {
  z-index: 999;
  position: relative;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.mission-detail__content .tab-steps {
  background: #FFF;
  height: 235px !important;
  min-height: 235px !important;
  max-height: 235px !important;
  width: 100%;
}
</style>
