<template>
  <div class="not-available tw-flex tw-flex-col tw-items-center tw-justify-between tw-flex-1">
    <div class="texts-container tw-flex tw-justify-center tw-items-center tw-flex-col">
      <span class="tw-text-blue-500 tw-font-medium mb-2">
        {{ $t('missions.tracking_not_available') }}
      </span>
      <i18n
        :path="timeLeftBeforePickup.key"
        tag="span"
      >
        <template #time>
          <span
            class="tw-text-blue-500 tw-font-medium"
          >
            {{ timeLeftBeforePickup.time }}
          </span>
        </template>
      </i18n>
    </div>
    <img
      :src="illuPlanned"
      class="img-illu"
    >
  </div>
</template>

<script>
  /**
   * @module component - notAvailable
   */
  import timeLeftFromNow from '@/services/TimeLeftFromNow'
  import IlluPlanned from './assets/illustration_planned.png'

  export default {
    name: 'NotAvailable',
    props: {
      mission: {
        type: Object,
        default: Object,
        required: true
      }
    },
    computed: {
      illuPlanned () {
        return IlluPlanned
      },
      timeLeftBeforePickup () {
        const { date, start_time: startTime } = this.mission.pickup.time_slot
        const { isInPast, fromNow } = timeLeftFromNow(date, startTime)
        return {
          key: isInPast ? 'missions.pickup_begin_in_x_time_passed' : 'missions.pickup_begin_in_x_time',
          time: fromNow
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .not-available {
    .texts-container {
      flex: 0 200px;
      margin: auto;
    }

    .img-illu {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 380px;
      margin-bottom: -3px;
    }
  }

</style>
