<template>
  <div class="ctk-list-filters-items">
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - CtkListFilterItems
   */
  export default {
    name: 'CtkListFilterItems'
  }
</script>
