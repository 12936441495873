<template>
  <div class="tab-cmr">
    <div class="py-4">
      <h5
        class="title tw-flex tw-items-center tw-font-light"
      >
        <ui-ctk-icon
          class="tw-text-blue-500"
          name="delivery-form"
          data-test="icon"
        />
        {{ $t('missions.proof_of_delivery_upload') }}
      </h5>
      <div
        v-if="getCurrentMission.delivery.state === 'late' && (getCurrentMission.proof_of_delivery && getCurrentMission.proof_of_delivery.state === 'pending')"
        class="m-2 p-3 tw-rounded"
        style="background-color: #ecd8d6 !important;"
      >
        <i18n
          :path="'missions.proof_of_delivery_explanation_text_now'"
        >
          <template #pod>
            <span>{{ $t('missions.proof_of_delivery_explanation_text_now.pod') }}</span>
          </template>
        </i18n>
      </div>
      <div
        v-else-if="getCurrentMission.delivery.state === 'completed' && (getCurrentMission.proof_of_delivery && getCurrentMission.proof_of_delivery.state === 'pending')"
        class="m-2 p-3 tw-rounded"
        style="background-color: #FFF !important; border: 1px solid #DDD;"
      >
        <i18n
          :path="'missions.proof_of_delivery_explanation_text_now'"
        >
          <template #pod>
            <span>{{ $t('missions.proof_of_delivery_explanation_text_now.pod') }}</span>
          </template>
        </i18n>
      </div>
      <proof-of-delivery />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import ProofOfDelivery from './ProofOfDelivery/index.vue'

  export default defineComponent({
    name: 'TabCmr',
    components: {
      ProofOfDelivery
    },
    computed: {
      ...mapGetters('missions', ['getCurrentMission'])
    }
  })
</script>
