<template>
  <div class="ctk-goods-infos-load">
    <!-- Load type & format -->
    <div
      class="tw-flex"
    >
      <div
        class="tw-flex-1 tw-flex tw-items-center"
        data-test="load-type"
      >
        <ui-ctk-icon
          name="dimension"
          class="tw-text-gray-700"
          data-test="load-type-icon"
        />
        <span
          data-test="load-type-value"
          class="ctk-goods-infos-load__value"
        >
          <template
            v-if="load.type === 'custom'"
          >
            {{ $t('prepared_goods') | capitalize }}
          </template>
          <template
            v-else-if="load.type === 'pallets'"
          >
            {{ $n(load.quantity) }} {{ $tc('pallet', load.quantity) | capitalize }}
          </template>
          <template
            v-else
          >
            {{ $t('unprepared_goods') | capitalize }}
          </template>
        </span>
      </div>

      <!-- Formats -->
      <div
        class="formats tw-flex-1 tw-flex tw-items-center"
        data-test="formats"
      >
        <ui-ctk-icon
          :name="load.type === 'pallets' ? 'pallet' : 'width-length'"
          class="tw-text-gray-700"
          data-test="formats-icon"
        />
        <span
          data-test="formats-value"
          class="ctk-goods-infos-load__value"
        >
          <template
            v-if="load.type === 'pallets'"
          >
            {{ load.format }} cm
          </template>
          <template
            v-else
          >
            {{ `${load.width}x${load.length} cm` }}
          </template>
        </span>
      </div>
    </div>

    <!-- Width & height -->
    <div
      class="tw-flex"
    >
      <!-- Height -->
      <div
        class="tw-flex-1 tw-flex tw-items-center"
        data-test="height"
      >
        <ui-ctk-icon
          name="height"
          class="tw-text-gray-700"
          data-test="height-icon"
        />
        <span
          data-test="height-value"
          class="ctk-goods-infos-load__value"
          v-text="`${$n(load.height)} cm`"
        />
      </div>

      <!-- Weight -->
      <div
        class="tw-flex-1 tw-flex tw-items-center"
        data-test="weight"
      >
        <ui-ctk-icon
          name="weight"
          class="tw-text-gray-700"
          data-test="weight-icon"
        />
        <span
          data-test="weight-value"
          class="ctk-goods-infos-load__value"
          v-text="$t('weight_unit', {
            weight: $n(load.weight)
          })"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'

  /**
   * @module component - CtkGoodsInfosLoad
   * @param {object} load
   */
  export default defineComponent({
    name: 'CtkGoodsInfosLoad',
    components: {
      UiCtkIcon
    },
    props: {
      load: {
        type: Object,
        required: true
      }
    }
  })
</script>
