<template>
  <ctk-item
    :is-current="isCurrentMission"
    :class="{
      'is-late': (isLate() || (isCmrPending && isLate('delivery'))) && !isCancelled,
      'is-cancelled': isCancelled,
      'has-selected': !!getCurrentMissionUuid
    }"
    touchable-type="button"
    class="mission-item tw-w-full text-left"
    role="button"
  >
    <span class="tw-flex tw-flex-col">
      <mission-item-header
        v-if="!(mission.state === 'in_progress' && !isLate())"
        :mission="mission"
        :is-late="isLate()"
        class="tw-w-full"
      />

      <div class="mission-item-content tw-flex tw-flex-row tw-justify-between">
        <mission-item-direction
          :mission="mission"
          class="tw-flex-1"
        />

        <mission-item-dates
          :mission="mission"
          class="tw-flex-1 dots-text"
        />

        <mission-item-tracking
          v-if="mission.state === 'in_progress' || mission.state === 'completed'"
          :mission="mission"
          class="tw-flex-1"
        />

        <mission-item-cancellation-date
          v-if="isCancelled"
          :mission="mission"
        />

        <mission-item-driver-info
          :driver="mission.driver"
        />
      </div>
    </span>
  </ctk-item>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import CtkItem from '@/components/CtkItem/index.vue'
  import MissionItemTracking from './_subs/MissionItemTracking/index.vue'
  import MissionItemDirection from './_subs/MissionItemDirection/index.vue'
  import MissionItemDates from './_subs/MissionItemDates/index.vue'
  import MissionItemCancellationDate from './_subs/MissionItemCancellationDate/index.vue'
  import MissionItemDriverInfo from './_subs/MissionItemDriverInfo/index.vue'
  import MissionItemHeader from './_subs/MissionItemHeader/index.vue'

  /**
   * Single mission item
   * @module component - missionItem
   * @param {object} mission
   */
  export default defineComponent({
    name: 'MissionItem',
    components: {
      CtkItem,
      MissionItemTracking,
      MissionItemDirection,
      MissionItemDates,
      MissionItemDriverInfo,
      MissionItemHeader,
      MissionItemCancellationDate
    },
    props: {
      mission: {
        type: Object,
        required: true
      },
      isCmrPending: {
        type: Boolean,
        default: null
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionUuid'
      ]),
      /**
       * @function isCurrentMission
       * @returns {boolean}
       */
      isCurrentMission () {
        return this.getCurrentMissionUuid === this.mission.uuid
      },
      /**
       * Returns true if the pickup and delivery are late.
       * If a parameter is passed, returns true if the direction is late.
       * @function isLate
       * @param {string} [direction]
       */
      isLate () {
        // @ts-ignore
        return (direction) => {
          // @ts-ignore
          const isLate = d => this.mission[d].state === 'late'

          return direction
            ? isLate(direction)
            : (isLate('pickup') || isLate('delivery'))
        }
      },
      /**
       * @function missionIsDelivered
       * @returns {boolean}
       */
      missionIsDelivered () {
        return (this.mission.pickup.state === 'completed' && this.mission.delivery.state === 'completed')
      },
      /**
       * Returns true if the mission is cancelled
       * @function isCancelled
       * @returns {boolean}
       */
      isCancelled () {
        return this.mission.state === 'cancelled'
      }
    }
  })
</script>

<style lang="scss" scoped>

  .mission-item {
    border-radius: 4px;
    border: 1px solid #E4E4E4;

    &-content {
      padding: 5px 30px 5px 30px;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        grid-row-gap: 16px;
        padding: 16px;

        .mission-item-direction,
        .mission-item-dates,
        .mission-item-driver-info,
        .mission-item-tracking {
          grid-column: span 4;
        }

        .mission-item-direction {
          min-height: 50px;
        }
      }

      @media only screen and (max-width: $breakpoint-mobile-s) {
        .mission-item-dates {
          flex-direction: column;
          min-height: 90px;
          overflow: visible;
        }
      }
    }

    &:focus,
    &.is-active {
      .mission-item-header {
        background: #DADADB;
      }
    }

    &.has-selected {
      .mission-item-driver-info {
        flex: 0 0 40px;
      }
    }

    &.is-late {
      $danger: #ECD8D6;

      border: 1px solid darken($danger, 5%);

      .mission-item-header {
        padding: 0 30px 0 22px;
        background: darken($danger, 5%);
        font-weight: 500;
      }

      &.is-current-mission {
        border: 1px solid darken($danger, 10%);

        .mission-item-header {
          background: darken($danger, 10%);
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      overflow: visible;

      &.is-cancelled {
        .mission-item-driver-info {
          grid-column: span 2;
          grid-row: 3;
        }

        .mission-item-cancellation-date {
          grid-column: span 2 / 5;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-m) {
      &.is-cancelled {
        .mission-item-driver-info {
          grid-column: span 4;
        }

        .mission-item-cancellation-date {
          grid-column: span 4;
        }
      }

      &.is-late .mission-item-header {
        padding: 0 16px;
      }
    }
  }

</style>
