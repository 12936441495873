var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-address-infos",class:_vm.direction,attrs:{"id":"ctkAddressInfos"}},[_c('div',{staticClass:"title-container"},[_c('h5',{staticClass:"ctk-address-infos__title tw-flex tw-items-center tw-mb-0 tw--ml-2"},[_c('ui-ctk-icon',{staticClass:"ctk-address-infos__title__icon tw-text-4xl",class:[
          _vm.direction === 'pickup'
            ? 'tw-text-blue-500'
            : 'tw-text-green-500'
        ],attrs:{"name":"marker"}}),_c('div',{staticClass:"ctk-address-infos__title__content tw-font-light tw--ml-1",domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(
          _vm.direction === 'delivery'
            ? _vm.$t('delivery')
            : _vm.$t('pickup')
        ))}})],1)]),_c('div',{staticClass:"address-card mb-3",class:[_vm.onlyOptions ? 'p-3' : 'px-2 py-3', {'dark': _vm.dark}]},[(!_vm.onlyOptions)?_c('div',{staticClass:"tw-flex tw-items-center mb-3 address-name"},[(_vm.fullAddress)?_c('span',{staticClass:"address-block"},[(_vm.getDirection.address.name)?_c('span',{staticClass:"tw-font-medium"},[_vm._v(" "+_vm._s(_vm.getDirection.address.name)+" ")]):_vm._e(),_c('br'),_c('ctk-address',{attrs:{"address":_vm.getDirection.address}})],1):_c('ctk-address',{attrs:{"address":_vm.getDirection.address},scopedSlots:_vm._u([{key:"country",fn:function(){return [_c('span',[_vm._v(" - "+_vm._s(_vm.$t(_vm.getDirection.address.country))+" ")])]},proxy:true}],null,false,3306354674)})],1):_vm._e(),(!!_vm.getDirection.address.accessibility && _vm.getDirection.address.accessibility.vehicle_type.key !== 'standard_trailer')?_c('ctk-address-accessibility',{staticClass:"tw-mb-4",class:{
        'only-options': _vm.onlyOptions
      },attrs:{"vehicle-type":_vm.getDirection.address.accessibility.vehicle_type.key}}):_vm._e(),(!_vm.onlyOptions)?_c('ctk-time-slot',{staticClass:"mt-3 mb-3",attrs:{"item":_vm.offer,"direction":_vm.direction,"dark":""}}):_vm._e(),(!_vm.onlyOptions || (_vm.onlyOptions && _vm.handlingByDriver))?_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-between",class:{'mb-1': (_vm.onlyOptions && _vm.handlingByDriver && _vm.hatchNeeded) || !_vm.onlyOptions}},[_c('div',{staticClass:"tw-flex tw-items-center tw-text-gray-700",domProps:{"textContent":_vm._s(_vm.$t('handling_by_the_driver'))}}),_c('div',{staticClass:"tw-flex tw-items-center tw-text-blue-500 tw-font-normal handling"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.handlingByDriver ? _vm.$t('yes') : _vm.$t('no')))+" ")])]):_vm._e(),(!_vm.onlyOptions || (_vm.onlyOptions && _vm.hatchNeeded))?_c('div',{staticClass:"tw-flex tw-flex-row tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-items-center tw-text-gray-700",domProps:{"textContent":_vm._s(_vm.$t('need_hatch'))}}),_c('div',{staticClass:"tw-flex tw-items-center tw-text-blue-500 tw-font-normal hatch"},[_vm._v(" "+_vm._s(_vm._f("uppercase")(_vm.hatchNeeded ? _vm.$t('yes') : _vm.$t('no')))+" ")])]):_vm._e(),(_vm.hasContactInformations)?_c('ctk-address-contact',{staticClass:"tw-mt-4",attrs:{"contact":_vm.contact}}):_vm._e(),(_vm.hasReference)?_c('ctk-address-reference',{staticClass:"tw-mt-4",attrs:{"offer":_vm.offer,"direction":_vm.direction}}):_vm._e(),(_vm.getDirection.comment)?_c('div',{class:{'mt-3': !_vm.onlyOptions || _vm.onlyOptions && (_vm.hatchNeeded || _vm.handlingByDriver)}},[_c('div',{staticClass:"tw-text-gray-700 tw-mb-1"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('comment')))+" ")]),_c('p',{staticClass:"tw-text-blue-500 tw-break-words tw-italic tw-mb-0 address-comment",domProps:{"textContent":_vm._s(_vm.getDirection.comment)}})]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }