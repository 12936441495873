<template>
  <div class="proof-of-delivery tw-h-full p-2 tw-flex-1 tw-flex">
    <proof-of-delivery-upload
      v-if="isPODPending"
    />
    <proof-of-delivery-view
      v-if="!isPODPending"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProofOfDeliveryUpload from './_subs/ProofOfDeliveryUpload'
  import ProofOfDeliveryView from './_subs/ProofOfDeliveryView'

  /**
   * @module component - proofOfDelivery
   */
  export default {
    name: 'ProofOfDelivery',
    components: {
      ProofOfDeliveryUpload,
      ProofOfDeliveryView
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission'
      ]),
      /**
       * @function isPODPending
       */
      isPODPending () {
        return (this.getCurrentMission.proof_of_delivery && this.getCurrentMission.proof_of_delivery.state === 'pending')
      }
    }
  }
</script>
