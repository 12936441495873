<template>
  <div
    v-infinite-scroll="retrieveMoreMissions"
    class="missions-list-content pt-2 pb-2 tw-flex-1 tw-flex tw-flex-col"
    infinite-scroll-distance="120"
  >
    <mission-item
      v-if="getMissionUuidToShow"
      :mission="getCurrentMission"
      :key="getCurrentMission.uuid"
      class="mb-3"
      data-test="mission-highlighted"
    />

    <mission-item
      v-for="mission in missions"
      :key="mission.uuid"
      :mission="mission"
      @click.native="setCurrentMission(mission)"
      class="mb-2 flex-fixed"
      data-test="mission"
    />

    <ctk-infinite-loader
      :loaded-text="$t(`missions.paragraphs.all_loaded.${state}`) | capitalize"
      :load-more-text="$t('missions.buttons.load_more') | capitalize"
      :is-loading="$wait.is('fetching more missions')"
      :can-load-more="canLoadMore"
      :items-count="getStoredMissions.length"
      @load-more="retrieveMoreMissions"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import MissionItem from '@/views/Carriers/Missions/components/MissionItem'
  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader'

  /**
   * @module component - missionsListContent
   */
  export default {
    name: 'MissionsListContent',
    components: {
      MissionItem,
      CtkInfiniteLoader
    },
    computed: {
      ...mapGetters('missions', [
        'getMissionUuidToShow',
        'getCurrentMission',
        'getCurrentMissionStatus',
        'getStoredMissions',
        'getMissionsMeta'
      ]),
      /**
       * Returns the route state, if not, the mission state
       * @function state
       */
      state () {
        // eslint-disable-next-line
        return !!this.getMissionUuidToShow
          ? this.getCurrentMissionStatus
          : this.$route.params.state || 'in_progress'
      },
      /**
       * Returns true if we can load more missions
       * @function canLoadMore
       */
      canLoadMore () {
        const { pagination } = this.getMissionsMeta
        return pagination.current_page < pagination.page_count
      },
      /**
       * Returns a list of missions
       * @function missions
       * @returns {Array}
       */
      missions () {
        return this.getStoredMissions.filter(mission => {
          return this.getMissionUuidToShow
            ? mission.uuid !== this.getMissionUuidToShow
            : true
        })
      }
    },
    methods: {
      ...mapActions('missions', [
        'setCurrentMission',
        'getMoreMissions'
      ]),
      /**
       * @function retrieveMoreMissions
       */
      retrieveMoreMissions () {
        this.$wait.start('fetching more missions')
        this.getMoreMissions({
          status: this.getCurrentMissionStatus,
          limit: 30
        }).finally(() => {
          this.$wait.end('fetching more missions')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .missions-list-content {
    overflow-y: auto;
    padding: 0 30px 0 53px;

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 0 16px 0 16px;
    }
  }

</style>
