<template>
  <ctk-list-header
    :title="$tc(`missions.state.${getCurrentMissionStatus}`, 2) | capitalize"
    :subtitle="$tc('missions.titles.count', getMissionsMeta.item_count, {
      count: $n(getMissionsMeta.item_count)
    })"
    class="missions-list-header flex-fixed"
  >
    <mission-list-header-filters />
  </ctk-list-header>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import MissionListHeaderFilters from './_subs/MissionListHeaderFilters/index.vue'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'

  /**
   * @module component - missionsListHeader
   */
  export default defineComponent({
    name: 'MissionsListHeader',
    components: {
      CtkListHeader,
      MissionListHeaderFilters
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionStatus',
        'getMissionsMeta'
      ])
    }
  })
</script>

<style lang="scss" scoped>
.missions-list-header {
  padding: 0 30px 0 53px;
}
.missions-list-header .ctk-list-filters {
  margin-top: 1rem;
}
@media (min-width: 770px) {
  .missions-list-header {
    flex: 0 0 68px;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .missions-list-header {
    padding: 0 16px 0 39px;
  }
}
.missions-list-header::after {
  left: 53px;
  width: calc(100% - 30px - 53px);
}
@media only screen and (max-width: $breakpoint-tablet) {
  .missions-list-header::after {
    left: 39px;
    width: calc(100% - 16px - 39px);
  }
}
</style>
