<template>
  <div class="ctk-time-slot-range tw-font-normal tw-whitespace-normal tw-relative tw-text-center tw-w-full tw-flex tw-items-center tw-justify-center tw-flex-col">
    <div
      class="tw-flex tw-justify-between tw-w-full"
    >
      <div
        class="flex-fixed tw-pr-2 tw-w-1/2 tw-whitespace-nowrap"
        data-test="start-time"
        v-text="startTime"
      />

      <div
        class="flex-fixed tw-w-1/2 tw-whitespace-nowrap"
        data-test="end-time"
        v-text="endTime"
      />
    </div>
    <div
      class="ctk-time-slot-range__slide tw-relative tw-w-1/2 tw-bottom-0"
    />
  </div>
</template>

<script>
  /**
   * @module component - CtkTimeSlotRange
   * @param {string} startTime
   * @param {string} endTime
   */
  export default {
    name: 'CtkTimeSlotRange',
    props: {
      startTime: {
        type: String,
        required: true
      },
      endTime: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-time-slot-range {
    &__slide {
      height: 6px;
      border-bottom: 1px solid lighten(#718096, 10%);

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent lighten(#718096, 10%) transparent;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

</style>
