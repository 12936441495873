<template>
  <div class="billing-infos">
    <!-- Cancelled -->
    <pricing-layout
      v-if="isCancelled && !hasEveryPaidInvoices"
      class="mb-3 tw-text-gray-700 py-3 billing-infos-cancelled border-ccc"
    >
      <span v-if="billing.state === 'pending'">
        {{ $t('missions.billing_text.mission_cancelled_with_pending_billing') }}
      </span>
      <i18n
        v-else-if="billing.state === 'completed'"
        path="missions.billing_text.mission_cancelled_with_invoices"
        tag="p"
      >
        <template #email>
          <a
            :href="`mailto:${chronoInfos.emails.billing}`"
            class="tw-text-blue-500 tw-font-medium"
          >
            {{ chronoInfos.emails.billing }}
          </a>
        </template>
      </i18n>

      <span v-else>
        {{ $t('missions.billing_text.mission_cancelled_without_invoices') }}
      </span>

      <billing-infos-contact
        v-if="billing && billing.invoices && billing.invoices.length"
        class="mt-3"
      />
    </pricing-layout>

    <!-- Pending -->
    <pricing-layout
      v-else-if="!isCompleted"
      class="mb-3 tw-text-gray-700 pt-3 pb-0 billing-infos-pending border-ccc"
    >
      <i18n
        :path="'missions.proof_of_delivery_explanation_text'"
        tag="p"
        class="px-3"
      >
        <template #pod>
          <strong
            class="tw-font-medium"
          >{{ $t('missions.proof_of_delivery_explanation_text.pod') }}</strong>
        </template>
      </i18n>

      <!-- Collapse -->
      <button
        class="collapse-header py-2 px-3 tw-w-full p-0"
        @click="details = !details"
      >
        <div class="tw-flex tw-justify-between">
          <span class="tw-font-normal title">
            {{ $t('missions.further_information') }}
          </span>
          <div
            :class="{'is-open': details}"
            class="collapse-button tw-flex tw-items-center tw-justify-center"
          >
            <ui-ctk-icon
              name="close"
              data-test="icon"
            />
          </div>
        </div>
      </button>
      <b-collapse
        id="contentCollapse"
        v-model="details"
        class="px-3"
      >
        <i18n
          v-if="getCurrentMission.pickup.state === 'pending' || getCurrentMission.delivery.state === 'pending'"
          :path="'missions.proof_of_delivery_explanation_text'"
          tag="p"
          class="mt-1"
        >
          <template #pod>
            <span>{{ $t('missions.proof_of_delivery_explanation_text.pod') }}</span>
          </template>
        </i18n>

        <p class="mt-1">
          {{ $t('missions.billing_explanation.pod') }}
        </p>

        <billing-infos-contact
          class="mb-3"
          email
        />
      </b-collapse>
    </pricing-layout>

    <!-- Completed -->
    <pricing-layout
      v-else
      class="mb-3 tw-text-gray-700 py-3 billing-infos-completed border-ccc"
    >
      <i18n
        :path="hasPod ? 'missions.billing_explanation_text' : 'missions.billing_explanation_text_without_pod'"
        tag="p"
        data-test="billing-infos-completed"
      >
        <template #email>
          <a
            v-text="chronoInfos.emails.billing"
            :href="`mailto:${chronoInfos.emails.billing}`"
            class="tw-text-blue-500"
          />
        </template>
      </i18n>
      <billing-infos-contact
        class="mt-3"
      />
    </pricing-layout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import BillingInfosContact from './_subs/BillingInfosContact/index.vue'

  /**
   * @module component - billingInfos
   * @param {object} billing
   */
  export default defineComponent({
    name: 'BillingInfos',
    components: {
      PricingLayout,
      BillingInfosContact
    },
    props: {
      billing: {
        type: Object,
        default: Object
      }
    },
    data () {
      return {
        chronoInfos: chronoinfos,
        details: false
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission'
      ]),
      isCompleted () {
        const { state } = this.getCurrentMission
        return state === 'completed' || this.hasPod
      },
      isCancelled () {
        return this.getCurrentMission.state === 'cancelled'
      },
      /**
       * @function hasPod
       * @returns {boolean}
       */
      hasPod () {
        const { proof_of_delivery: pod } = this.getCurrentMission
        return pod && pod.state === 'available'
      },
      /**
       * Returns true if every invoice in the billing is paid
       * @function hasEveryPaidInvoices
       * @returns {boolean}
       */
      hasEveryPaidInvoices () {
        const { invoices } = this.billing
        if (!invoices) return false
        return invoices.length > 0 && invoices.every(invoice => invoice.state === 'paid')
      }
    }
  })
</script>

<style lang="scss" scoped>

  $button-size: 20px;

  .billing-infos {
    .tw-font-medium {
      color: rgba(black, 0.7);
    }

    .border-ccc {
      border: 1px solid #E6E6E6;
    }

    &-pending {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .collapse {
      &-header {
        cursor: pointer;
        background-color: white;
        border: none;
        outline: none;
        border-top: 1px solid #E6E6E6;
        border-radius: 0 0 4px 4px;

        &:hover {
          background-color: rgba(black, 0.05);
        }
      }

      &-button {
        background-color: #CCC;
        border-radius: $button-size;
        color: white;
        height: $button-size;
        width: $button-size;
        padding: 0;
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        transform: rotate(-45deg);

        i {
          font-size: $button-size - 4;
          font-weight: 600;
        }

        &.is-open {
          transform: rotate(0);
        }
      }
    }

    .title {
      color: #505050;
    }
  }

</style>
