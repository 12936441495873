<template>
  <ctk-item-tracking
    :active-step="activeStep"
    :label="$t(label)"
    class="mission-item-tracking"
  >
    <ctk-item-tracking-dot
      :active="isAwaitingPickup"
      :completed="isAwaitingDelivery || isAwaitingPOD || isAwaitingBilling || isPODAvailable"
      data-test="pickup"
    />
    <ctk-item-tracking-divider
      :completed="isAwaitingDelivery || isAwaitingPOD || isAwaitingBilling || isPODAvailable"
      data-test="pickup-to-delivery"
    />
    <ctk-item-tracking-dot
      :active="isAwaitingDelivery"
      :completed="isAwaitingPOD || isAwaitingBilling || isPODAvailable"
      data-test="delivery"
    />
    <ctk-item-tracking-divider
      :completed="isAwaitingPOD || isAwaitingBilling || isPODAvailable"
      data-test="delivery-to-pod"
    />
    <ctk-item-tracking-dot
      :active="isAwaitingPOD"
      :completed="isAwaitingBilling || isPODAvailable"
      data-test="pod"
    />
    <ctk-item-tracking-divider
      :completed="isAwaitingBilling || isPODAvailable"
      data-test="pod-to-billing"
    />
    <ctk-item-tracking-dot
      :active="isAwaitingBilling"
      :completed="isPODAvailable"
      data-test="billing"
    />
  </ctk-item-tracking>
</template>

<script>
  import CtkItemTracking from '@/components/CtkItem/CtkItemTracking'
  import CtkItemTrackingDot from '@/components/CtkItem/CtkItemTracking/_subs/CtkItemTrackingDot'
  import CtkItemTrackingDivider from '@/components/CtkItem/CtkItemTracking/_subs/CtkItemTrackingDivider'

  /**
   * @module component - MissionItemTracking
   * @param {object} mission
   */
  export default {
    name: 'MissionItemTracking',
    components: {
      CtkItemTracking,
      CtkItemTrackingDot,
      CtkItemTrackingDivider
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns the appropriate translation label, according to the mission states
       * @function label
       * @returns {string}
       */
      label () {
        if (this.isPODAvailable) return 'missions.labels.pod_uploaded'
        if (this.isAwaitingBilling) return 'missions.labels.waiting_invoice'
        if (this.isAwaitingPOD) return 'missions.labels.to_upload_pod'
        if (this.isAwaitingDelivery) return 'missions.labels.to_deliver'
        return 'missions.labels.to_pickup'
      },
      /**
       * Returns the appropriate step according to the mission states
       * @function activeStep
       * @returns {number}
       */
      activeStep () {
        if (this.isPODAvailable || this.isAwaitingBilling) return 3
        if (this.isAwaitingPOD) return 2
        if (this.isAwaitingDelivery) return 1
        return 0
      },
      /**
       * Returns true if we're awaiting for pickup
       * @function isAwaitingPickup
       * @returns {boolean}
       */
      isAwaitingPickup () {
        const { pickup } = this.mission
        return ['late', 'pending'].includes(pickup.state)
      },
      /**
       * Returns true if we're awaiting for delivery
       * @function isAwaitingDelivery
       * @returns {boolean}
       */
      isAwaitingDelivery () {
        const { pickup, delivery } = this.mission
        return pickup.state === 'completed' && delivery.state === 'pending'
      },
      /**
       * Returns true if we're awaiting the POD
       * @function isAwaitingPOD
       * @returns {boolean}
       */
      isAwaitingPOD () {
        const { delivery, proof_of_delivery: POD } = this.mission
        const isDeliveryDone = ['late', 'completed'].includes(delivery.state)
        return isDeliveryDone && POD.state === 'pending'
      },
      /**
       * Returns true if the mission is awaiting for billing
       * @function isAwaitingBilling
       * @returns {boolean}
       */
      isAwaitingBilling () {
        const { state, pickup, delivery, proof_of_delivery: POD } = this.mission
        const isCompleted = state === 'completed'
        const isPickupCompleted = pickup.state === 'completed'
        const isDeliveryCompleted = delivery.state === 'completed'

        return !isCompleted &&
          isPickupCompleted &&
          isDeliveryCompleted &&
          POD &&
          POD.state !== 'pending'
      },
      /**
       * Returns true if the POD is available
       * @function isPODAvailable
       * @returns {boolean}
       */
      isPODAvailable () {
        const { state, proof_of_delivery: POD } = this.mission
        return state === 'completed' && POD.state === 'available'
      }
    }
  }
</script>

<style lang="scss">

  @media only screen and (max-width: $breakpoint-tablet) {
    .mission-item-tracking {
      .ctk-item-tracking__wrapper {
        width: 100%;
      }
    }
  }

</style>
