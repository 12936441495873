<template>
  <div class="mission-item-driver-info tw-flex">
    <ctk-item-carrier
      :user="driver"
      :name="name"
      :avatar-only="!!getCurrentMissionUuid"
      :class="{
        'tw-justify-end': !!getCurrentMissionUuid
      }"
      class="mission-item-driver-info__carrier"
    >
      {{ driver.phone ? $options.filters.telephone(driver.phone) : '' }}
    </ctk-item-carrier>
    <ctk-avatar
      :user="driver"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import CtkItemCarrier from '@/components/CtkItem/CtkItemCarrier/index.vue'

  /**
   * @module component - missionItemDriverInfo
   * @param {object} driver
   */
  export default defineComponent({
    name: 'MissionItemDriverInfo',
    components: {
      CtkAvatar,
      CtkItemCarrier
    },
    props: {
      driver: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionUuid'
      ]),
      /**
       * Returns the concatenated driver's name
       * @function name
       * @returns {string} name
       */
      name () {
        const {
          first_name: firstName,
          last_name: lastName
        } = this.driver
        const capitalize = s => this.$options.filters.capitalize(s)

        return `${capitalize(firstName)} ${capitalize(lastName)}`
      }
    }
  })
</script>

<style lang="scss">

  .mission-item-driver-info {
    flex: 0 0 240px;

    &__carrier {
      width: auto !important;
    }

    .ctk-avatar,
    .ctk-item-carrier {
      margin: auto 0;
    }

    .ctk-avatar {
      display: none;
    }

    &.tw-justify-end {
      flex: 0 0 50px;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      flex: 0 auto;
    }

    @media only screen and (min-width: $breakpoint-laptop-s) and (max-width: 1280px) {
      flex: 0 0 40px;

      &__carrier {
        display: none;
      }

      .ctk-avatar {
        display: block;
      }
    }
  }

</style>
