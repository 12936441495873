<template>
  <div class="missions-list tw-flex tw-flex-1 tw-flex-col dots-text">
    <missions-list-header
      :class="{
        '--empty-state': getStoredMissions.length === 0
      }"
      key="header"
    />
    <missions-list-content
      v-if="getStoredMissions.length > 0 && !$wait.is('fetching missions')"
      key="content"
    />

    <div
      v-if="getStoredMissions.length === 0 && !$wait.is('fetching missions')"
      class="tw-flex tw-flex-1 missions-list__empty tw-pb-8"
    >
      <ui-button
        v-if="!isAccountLocked"
        :to="{ name: 'Offers' }"
        variant="link"
        class="tw-flex tw-items-center tw-justify-center tw-m-auto"
      >
        {{ $t('missions.button_text.book_an_offer') }}
      </ui-button>
    </div>

    <ctk-loading-layer
      v-if="$wait.is('fetching missions')"
      :has-background="false"
    >
      {{ $t('missions.loading') | capitalize }}
    </ctk-loading-layer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkLoadingLayer from '@/components/CtkLoadingLayer'
  import MissionsListHeader from './_subs/MissionsListHeader'
  import MissionsListContent from './_subs/MissionsListContent'

  /**
   * @module component - missionsList
   */
  export default {
    name: 'MissionsList',
    components: {
      CtkLoadingLayer,
      MissionsListHeader,
      MissionsListContent
    },
    computed: {
      ...mapGetters('auth', [
        'isAccountLocked'
      ]),
      ...mapGetters('missions', [
        'getStoredMissions'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .missions-list {
    position: relative;

    &__empty {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('~@/assets/img/illustrations/shipments.svg');
        height: 300px;
        width: 100%;
        opacity: 0.8;
        top: 0;
        bottom: 0;
        z-index: -1;
        margin: auto 0;
      }
    }
  }

</style>
