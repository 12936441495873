<template>
  <div class="tw-w-full tw-h-full icono-bg">
    <div class="content tw-flex tw-items-center tw-justify-center p-5">
      <h1 class="confirm-modal-titles-primary">
        {{ $t('missions.final_step_dialog.title_1') | capitalize }}
      </h1>
      <h2 class="confirm-modal-titles-secondary mb-5">
        {{ $t('missions.final_step_dialog.title_2') | capitalize }}
      </h2>
      <div
        class="final-step-dialog-content tw-text-center mb-3"
        v-markdown="$t('missions.final_step_dialog.content')"
      />
      <ui-button
        variant="primary"
        size="lg"
        class="mb-2"
        @click="goToBillingTab"
      >
        {{ $t('missions.final_step_dialog.billing_button') | capitalize }}
      </ui-button>
      <a
        id="closeFinalStepDialog"
        href="javascript:void(0)"
        class="tw-text-blue-500"
        @click="closeDialog"
      >
        {{ $t('close') | capitalize }}
      </a>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  export default {
    name: 'FinalStepMission',
    props: {
      value: {
        type: Boolean,
        default: null
      }
    },
    methods: {
      closeDialog () {
        this.$emit('close-dialog')
      },
      goToBillingTab () {
        this.$emit('close-dialog')
        EventBus.$emit('update-mission-detail-current-tab', 2)
      }
    }
  }
</script>

<style lang="scss">

  .final-step-dialog-content .markdown {
    p {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }

</style>
