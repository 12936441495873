<template>
  <div
    class="driver-infos"
  >
    <h5 class="tw-font-light">
      {{ $t('missions.mission_detail.label.driver') }}
    </h5>
    <div
      class="info-container driver-container tw-bg-white tw-rounded px-2 py-2 mb-4 tw-flex tw-justify-between"
    >
      <div class="tw-flex-1 tw-flex tw-flex-col tw-flex tw-justify-center dots-text">
        <span class="tw-font-medium dots-text">
          {{ driver.first_name }} {{ driver.last_name }}
        </span>
        <p
          v-if="driver.phone"
          class="tw-text-gray-700 m-0"
        >
          {{ driver.phone | telephone }}
        </p>
      </div>

      <ui-button
        v-if="isChangeDriverAvailable"
        variant="link"
        class="tw-flex-shrink-0"
        @click="openChangeDriverDialog"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="edit"
          />
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  import { mapGetters } from 'vuex'

  export default {
    name: 'DriverInfos',
    props: {
      driver: {
        type: Object,
        required: true,
        default: Object
      },
      mission: {
        type: Object,
        required: true,
        default: Object
      }
    },
    computed: {
      ...mapGetters(['isUserMono']),
      isChangeDriverAvailable () {
        return (this.mission.state === 'planned' || this.mission.state === 'in_progress') && this.mission.delivery.state !== 'completed' && !this.isUserMono
      }
    },
    methods: {
      openChangeDriverDialog () {
        EventBus.$emit('open-change-driver-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .driver-container {
    transition: all 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &.animated.flash {
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 18px 0 rgba(0, 0, 0, 0.08) !important;
    }
  }

</style>
