<template>
  <div class="ctk-list-filters-select">
    <ctk-list-filters-select-button
      ref="button"
      v-model="open"
      :has-dot="hasDot"
      @input="buttonInput"
      @keyup.native="checkShow"
    >
      {{ filterText }}
    </ctk-list-filters-select-button>

    <transition name="slide">
      <ctk-list-filters-select-list
        ref="list"
        v-if="open"
        v-model="filter"
        :open.sync="open"
        :id="id"
        :items="filters"
        :multiple="multiple"
        @re-focus="refocus"
      />
    </transition>
  </div>
</template>

<script>
  import { defineComponent, ref, watch } from '@vue/composition-api'

  import { KEYCODES } from '@/composables/constants'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkListFiltersSelectList from './_subs/CtkListFiltersSelectList/index.vue'
  import CtkListFiltersSelectButton from './_subs/CtkListFiltersSelectButton/index.vue'

  /**
   * @module component - CtkListFiltersSelect
   * @param {string} id
   * @param {Array<string>} filters
   * @param {boolean} [multiple=false]
   * @param {boolean} [hasDot=false]
   */
  export default defineComponent({
    name: 'CtkListFiltersSelect',
    components: {
      CtkListFiltersSelectButton,
      CtkListFiltersSelectList
    },
    props: {
      id: {
        type: [String, Number],
        required: true
      },
      filters: {
        type: Array,
        required: true
      },
      value: {
        type: [String, Number, Array],
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      hasDot: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: filter } = useModelGetterSetter(props, 'value')
      const open = ref(false)

      watch(filter, () => {
        open.value = false
      })

      return {
        filter,
        open
      }
    },
    computed: {
      /**
       * Returns the wording to use as the filter button textual value.
       * @function filterText
       */
      filterText () {
        if (this.multiple && this.filter.length > 0) {
          return this.$t('app.labels.filters', { count: this.filter.length })
        }

        if (this.multiple && this.filter.length === 0) {
          return this.$t('app.titles.filters')
        }

        if (!this.multiple && this.filter && this.filter.length > 0) {
          return `${this.$t('app.labels.filter')} ${this.filter[0].text}`
        }

        return this.$t('app.titles.filters')
      }
    },
    methods: {
      buttonInput (v) {
        if (v) this.show()
      },
      /**
       * @function checkShow
       * @param {KeyboardEvent} e
       */
      checkShow (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          this.show()
          break
        }
      },
      refocus () {
        this.$refs.button.focus()
      },
      async show () {
        this.open = true
        await this.$nextTick()
        if (this.$refs.list && this.$refs.list.$children[0]) {
          this.$refs.list.$children[0].focus()
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-list-filters-select {
    position: relative;

    .ctk-list-filters-select-list {
      right: 0;
    }
  }

</style>
