<template>
  <div
    id="missions"
    ref="missions"
    class="missions tw-flex tw-flex-1"
  >
    <ctk-side-bar
      :width="350"
      :open="isSidebarOpen"
      class="missions-menu tw-flex tw-flex-col"
      @toggle-menu="toggleSidebar"
    >
      <mission-sections
        style="padding-bottom: 100px;"
      />
    </ctk-side-bar>
    <missions-list />

    <ctk-side-bar
      :width="380"
      :open="!!getCurrentMissionUuid"
      class="missions-sidebar"
      without-close-btn
      right
    >
      <mission-detail v-if="getCurrentMissionUuid" />
    </ctk-side-bar>

    <change-driver-dialog
      v-model="changeDriverDialog"
    />

    <b-modal
      id="finalStepDialog"
      v-model="finalStepDialog"
      class="confirm-modal final-step-dialog"
      modal-class="confirm-modal final-step-dialog"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <final-step-dialog-content @close-dialog="finalStepDialog = false" />
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import MissionSections from './_subs/MissionSections.vue'
  import MissionsList from './_subs/MissionsList/index.vue'
  import CtkSideBar from '@/components/CtkSideBar/index.vue'
  import MissionDetail from './_subs/MissionDetail/index.vue'
  import FinalStepDialogContent from './_subs/finalStepDialogContent.vue'
  import ChangeDriverDialog from '@/views/Carriers/Missions/components/ChangeDriverDialog/index.vue'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'

  export default defineComponent({
    name: 'Missions',
    components: {
      MissionSections,
      MissionsList,
      CtkSideBar,
      MissionDetail,
      FinalStepDialogContent,
      ChangeDriverDialog
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      if (to.name !== 'Mission') {
        if (
          (store.getters.isUserMono && to.params.type === 'company') ||
          (store.getters.isUserDispatcher && to.params.type === 'me')
        ) {
          return next({ name: 'MissionsRouter' })
        }
      }
      next()
    },
    data () {
      return {
        stateInterval: null,
        askToggle: false,
        iframeNotLoaded: true,
        fileChecking: true,
        isAnImage: null,
        finalStepDialog: false,
        changeDriverDialog: false
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getStoredMissions',
        'getCurrentMissionStatus',
        'getCurrentMissionUuid'
      ]),
      ...mapGetters([
        'isUserMono',
        'isUserDispatcher'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      missions () {
        return this.getStoredMissions
      }
    },
    watch: {
      $route: {
        handler: function (to) {
          /**
           * When the navigation comes from the mission/:uuid view to
           * another mission list state, ensure to remove the highlighted mission
           */
          if (to.name === 'Missions') {
            this.setMissionUuidToShow(null)
          }

          /**
           * Before switching to another route, reset the mission filters
           */
          this.resetMissionsFilters()
          this.fetchMissions(to, to.params.state)
        },
        deep: true
      }
    },
    created () {
      /** @type {string|null} */
      let missionState
      this.$store.commit('missions/RESET_CURRENT_MISSION')
      this.$wait.start('fetching missions')

      if (this.$route.name === 'Mission') {
        const missionUuid = this.$route.params.uuid
        this.$wait.start('fetching mission')
        this.getMission(missionUuid)
          .then(response => {
            missionState = response.data.state
          })
          .catch(() => {
            this.$router.replace({
              name: 'MissionsRouter'
            }).catch(() => {})
          })
          .finally(() => this.$wait.end('fetching mission'))
      } else {
        missionState = this.$route.params.state
      }

      /**
       * Fetch metrics
       */
      this.fetchMetrics()

      /**
       * Fetch the missions
       */
      this.stateInterval = setInterval(() => {
        if (typeof missionState !== 'undefined') {
          clearInterval(this.stateInterval)
          this.$store.commit('missions/SET_CURRENT_MISSION_STATUS', missionState)
          this.fetchMissions(this.$route, missionState)
        }
      }, 300)
    },
    mounted () {
      // Responsive side menu toggle
      window.addEventListener('resize', this.toggleSideMenu)

      EventBus.$on('missions:sidebar-visible', (value) => {
        this.setSidebarOpen(value)
      })

      EventBus.$on('launch-final-step', () => {
        this.finalStepDialog = true
      })
      EventBus.$on('open-change-driver-dialog', () => {
        this.changeDriverDialog = true
      })

      EventBus.$on('missions:refresh-list', () => {
        this.fetchMissions(this.$route, this.$route.params.state)
      })
    },
    beforeDestroy () {
      if (this.stateInterval) {
        clearInterval(this.stateInterval)
      }

      const events = ['launch-final-step', 'open-change-driver-dialog', 'missions:refresh-list']
      events.forEach(event => {
        EventBus.$off(event)
      })

      window.removeEventListener('resize', this.toggleSideMenu)
    },
    methods: {
      ...mapActions([
        'setAppReady'
      ]),
      ...mapActions('ui', [
        'toggleSidebar',
        'setSidebarOpen'
      ]),
      ...mapActions('missions', [
        'getMissions',
        'getMetrics',
        'setCurrentMissionStatus',
        'getMission',
        'resetMissionsFilters',
        'setMissionUuidToShow'
      ]),
      fetchMetrics () {
        this.getMetrics({
          type: 'company'
        })
          .catch(err => {
            if (!err.response) return

            console.log('error get metrics', err)
          })
      },
      fetchMissions (route, state) {
        this.setCurrentMissionStatus(state)

        this.$wait.start('fetching missions')
        this.getMissions({
          status: state,
          limit: 30,
          type: route.params.type || (this.isUserMono ? 'me' : 'company')
        })
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching missions')
            this.setAppReady(true)
          })
      },
      toggleSideMenu () {
        const width = document.documentElement.clientWidth
        if (!this.askToggle && width <= 1024) {
          this.askToggle = true
          this.setSidebarOpen(false)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  #missions {
    background: #F2F2F2;
    z-index: 2;
    overflow: auto;

    .missions-sidebar {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        position: absolute;
        right: 0;
        left: auto;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        &:not(.hide-side-bar) {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }

</style>
