<template>
  <div>
    <b-tooltip
      :title="$t('missions.explanation_pod_file')"
      target="help-button"
      placement="top"
      boundary="window"
    />
    <div
      id="help-button"
      class="tw-text-blue-500 hover:tw-text-blue-600 tw-flex tw-items-center tw-cursor-pointer"
    >
      <ui-ctk-icon
        name="help"
        data-test="icon"
      />
      <span
        v-text="$t('missions.help.label')"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - ProofOfDeliveryUploadHelpButton
   */
  export default {
    name: 'ProofOfDeliveryUploadHelpButton'
  }
</script>
