<template>
  <div class="billing-infos-contact p-2 tw-rounded mb-2">
    <div class="billing-infos-contact__invoice-to">
      <h3
        v-text="$t('missions.billing_explanation.invoice_to')"
      />
      <address class="m-0">
        {{ chronoInfos.name }}<br>
        {{ chronoInfos.location.address }}<br>
        {{ chronoInfos.location.postalCode }}, {{ chronoInfos.location.city }}<br>
        {{ $t('missions.billing_explanation.vat') }} {{ chronoInfos.VAT }}<br>
      </address>
    </div>
    <div
      v-if="email"
      class="billing-infos-contact__email-to mt-3 pt-3"
    >
      <h3
        v-text="$t('missions.billing_explanation.email_to')"
      />
      <a
        :href="`mailto:${chronoInfos.emails.billing}`"
        target="_blank"
        class="tw-text-blue-500 hover:tw-text-blue-600"
        v-text="chronoInfos.emails.billing"
      />
    </div>
  </div>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - billingInfosContact
   * @param {boolean} email - Show the email to part
   */
  export default {
    name: 'BillingInfosContact',
    props: {
      email: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        chronoInfos: chronoinfos
      }
    }
  }
</script>

<style lang="scss" scoped>

  .billing-infos-contact {
    background-color: #F9F9F9;
    border: 1px solid #DDD;

    h3 {
      font-size: 1.1em;
      color: rgba(black, 0.7);
    }

    &__email-to {
      border-top: 1px solid rgba(black, 0.1);
    }
  }

</style>
