<template>
  <div class="ctk-handling-infos mb-3">
    <div
      class="ctk-handling-infos__container tw-flex tw-justify-between"
      v-for="(info, k) in handlingInfos"
      :key="k"
    >
      <div
        class="ctk-handling-infos__label"
        :class="{'tw-text-gray-700': dark }"
      >
        {{ info.label }}
      </div>
      <div
        class="ctk-handling-infos__value tw-font-medium"
        :class="[textClass]"
      >
        {{ info.value | uppercase }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CtkHandlingInfos',
    props: {
      item: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      textClass: {
        type: String,
        default: 'tw-text-blue-500'
      },
      dark: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      handlingInfos () {
        return [
          {
            label: this.$t('handling_by_the_driver'),
            value: this.$t(this.item[this.direction].handling.driver ? 'yes' : 'no')
          },
          {
            label: this.$t('need_hatch'),
            value: this.$t(this.item[this.direction].handling.tail_lift ? 'yes' : 'no')
          }
        ]
      }
    }
  }
</script>
