<template>
  <div
    class="mission-item-direction tw-flex tw-flex-col tw-flex-1 pr-2"
  >
    <ctk-address
      v-for="direction in ['pickup', 'delivery']"
      :key="direction"
      :has-street-name="false"
      :address="mission[direction].address"
      class="tw-flex dots-text tw-flex-1"
    />
  </div>
</template>

<script>
  import CtkAddress from '@/components/CtkAddress'

  /**
   * @module component - missionItemDirection
   * @param {object} mission
   */
  export default {
    name: 'MissionItemDirection',
    components: {
      CtkAddress
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .mission-item-direction {
    position: relative;
    min-width: 0;

    &::after {
      position: absolute;
      content: '';
      background-image: url('~@/assets/img/division-line.svg');
      height: 9px;
      width: 1px;
      left: 9px;
      top: 19px;
    }
  }

</style>
