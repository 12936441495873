<template>
  <div
    id="CtkStepperBar"
    class="tw-flex"
  >
    <div
      v-for="(label, index) in steps"
      :key="index"
      :class="{'active': currentStep === index, 'done': currentStep > index}"
      class="step tw-flex-1 tw-flex tw-items-center tw-justify-center"
    >
      <div class="number-circle tw-flex align center tw-justify-center">
        <ui-material-icon
          v-if="currentStep > index"
          name="check"
        />

        <div v-else>
          {{ index + 1 }}
        </div>
      </div>
      <div
        v-show="currentStep === index"
        class="step-label"
      >
        {{ label | capitalize }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CtkStepperBar',
    props: {
      steps: {
        type: Array,
        required: true
      },
      currentStep: {
        type: Number,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  #CtkStepperBar {
    transition: all 0.5s;

    .step {
      height: 40px;
      transition: all 0.5s;
      background: #F2F2F2;
      margin: 0 2px 0 0;
      color: $info;
      position: relative;
      flex: 2;

      &::after,
      &::before {
        content: '';
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        position: absolute;
        top: 0;
        transition: border-left 0.5s;
        transform: translateX(-0.2px);
      }

      &::after {
        border-left: 12px solid #F2F2F2;
        right: -12px;
        z-index: 1;
      }

      &::before {
        border-left: 12px solid #FFF;
        left: 0;
      }

      .number-circle {
        border: 1px solid $info;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: -12px;

        i {
          font-size: 18px;
        }
      }

      &.active {
        .number-circle {
          border: 1px solid #FFF;
          margin-right: 10px;
        }

        background: $info;
        color: #FFF;
        flex: 8;

        &::after {
          border-left: 12px solid $info;
        }
      }

      &.done {
        .number-circle {
          border: 1px solid #FFF;
          background: #FFF;
          color: $primary;
        }

        background: $primary;
        color: #FFF;

        &::after {
          border-left: 12px solid $primary;
        }
      }

      &:first-child {
        border-top-left-radius: 10px;

        &::before {
          display: none;
        }
      }

      &:last-child {
        margin: 0;
        border-top-right-radius: 10px;

        &::after {
          display: none;
        }
      }
    }
  }

</style>
