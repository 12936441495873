<template>
  <router-view />
</template>

<script>
  import store from '@/store'

  export default {
    name: 'MissionsRouter',
    metaInfo () {
      return {
        title: this.$t('missions.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      next()
    }
  }
</script>
