<template>
  <div class="ctk-address-contact">
    <div
      v-text="$t('app.labels.address.contact_informations')"
      data-test="label"
      class="tw-text-gray-700"
    />
    <p
      v-text="`${contact.name ? contact.name : ''} ${contact.phone ? $options.filters.telephone(contact.phone) : ''}`"
      data-test="value"
      class="ctk-address-contact__content tw-break-words tw-whitespace-pre-line"
    />
  </div>
</template>

<script>
  /**
   * @module component - CtkAddressContact
   * @param {object} contact
   */
  export default {
    name: 'CtkAddressContact',
    props: {
      contact: {
        type: Object,
        required: true
      }
    }
  }
</script>
