<script>
  import UiSelectButtonButton from '@/components/UI/SelectButton/_subs/UiSelectButtonButton'

  /**
   * @module component - CtkListFiltersSelectButton
   */
  export default {
    name: 'CtkListFiltersSelectButton',
    render (h) {
      return h(UiSelectButtonButton, {
        class: ['ctk-list-filters-select-button'],
        on: {
          ...this.$listeners
        },
        attrs: {
          ...this.$attrs
        },
        props: {
          ...this.$props
        },
        scopedSlots: {
          default: () => this.$slots.default
        }
      })
    }
  }
</script>
