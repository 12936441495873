<template>
  <vue-dropzone
    id="CtkDropzone"
    ref="vueDropzone"
    :options="dropzoneOptions"
    class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-rounded"
    @vdropzone-file-added="reset"
    @vdropzone-success="fileSended"
    @vdropzone-error="fileError"
  />
</template>

<script>
  import VueDropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  import { mapGetters } from 'vuex'

  const maxFilesize = 5

  /**
   * @module component - ctkDropzone
   * @param {string} url
   * @emits file-upload-success
   * @emits file-upload-error
   */
  export default {
    name: 'CtkDropzone',
    components: { VueDropzone },
    props: {
      url: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getToken',
        'getImpersonate'
      ]),
      dropzoneOptions () {
        const headers = {
          Authorization: `Bearer ${this.getToken}`
        }

        const impersonate = this.getImpersonate
        if (impersonate) {
          headers['X-Impersonate'] = impersonate.username
        }

        return {
          url: this.url,
          acceptedFiles: 'image/*,application/pdf',
          maxFilesize: maxFilesize,
          maxFiles: 1,
          dictDefaultMessage: `
            <i class="material-icons" aria-hidden="true">cloud_upload</i>
            <br />
            <span>${this.$t('missions.choose_or_drop_a_file')}</span>
            <br />
            <span class="tw-text-xs">${this.$t('missions.file_details', { size: maxFilesize })}</span>
          `,
          dictFallbackMessage: this.$t('missions.dropzone.browser_is_not_supported'),
          dictFileTooBig: this.$t('missions.dropzone.file_is_too_big'),
          dictInvalidFileType: this.$t('missions.dropzone.invalid_type'),
          previewTemplate: `
            <div class="dz-preview dz-file-preview tw-w-full">
              <div class="dz-progress tw-w-full tw-m-0">
                <span class="progress-title tw-text-center tw-w-full">
                  ${this.$t('missions.dropzone.loading')}
                </span>
                <span class="dz-upload" data-dz-uploadprogress></span>
              </div>
              <div class="dz-success-mark tw-w-full tw-text-center">
                <i class="ctk-font icon-ctk-done"></i>
              </div>
            </div>
          `,
          headers,
          paramName: 'proof_of_delivery'
        }
      }
    },
    watch: {
      url (val) {
        this.$refs.vueDropzone.setOption('url', val)
      }
    },
    mounted () {
      this.$refs.vueDropzone.setOption('url', this.url)
    },
    methods: {
      /**
       * Called whenever a new file is dropped in the zone. Should reset
       * the error messages.
       * @function reset
       */
      reset () {
        this.$emit('file-upload-error', null)
      },
      /**
       * Called when the file is successfully sent.
       * @function fileSended
       */
      fileSended (file, response) {
        setTimeout(() => {
          this.$emit('file-upload-success', response)
        }, 2000)
      },
      /**
       * Called when an error occured while uploading the file.
       * @function fileError
       */
      fileError (file, error) {
        this.$emit('file-upload-error', error)
        this.$refs.vueDropzone.removeAllFiles()
      }
    }
  }
</script>

<style lang="scss">

  #CtkDropzone {
    border: 1px dashed lightgray;
    min-height: 200px;

    .dz-preview {
      margin: 0;
      min-height: auto;
      background: transparent;
    }

    .dz-progress {
      left: 0;
      overflow: visible;
      background-color: lightgray;
      width: 100%;

      .dz-upload {
        background-color: $primary;
        border-radius: 16px;
      }

      .progress-title {
        display: inline-block;
        position: relative;
        top: -30px;
      }
    }

    .dz-message {
      margin: 0;
    }

    .dz-success-mark {
      position: relative;
      top: 0;

      .ctk-font {
        color: $primary;
        font-size: 70px;
      }
    }

    .dz-remove {
      display: none;
    }

    &:hover {
      i {
        color: $primary !important;
      }
    }
  }

</style>
