<template>
  <div
    id="ctkGoodsInfos"
    class="ctk-goods-infos"
  >
    <h5
      v-if="!noTitle"
      class="ctk-goods-infos__title tw-flex tw-items-center tw-mb-0 tw--ml-2"
      data-test="title"
    >
      <ui-ctk-icon
        class="ctk-address-infos__title__icon tw-text-4xl tw-text-blue-500"
        name="pallets"
        data-test="icon"
      />
      <div
        class="ctk-goods-infos__title__content tw-font-light tw--ml-1"
        v-text="$options.filters.capitalize($tc('good', 2))"
      />
    </h5>

    <div
      :class="[onlyOptions ? 'tw-p-4' : 'tw-px-2 tw-py-4', {'dark': dark}]"
      class="ctk-goods-infos__content tw-relative tw-bg-white tw-rounded goods-card"
    >
      <ctk-goods-infos-load
        :load="offer.load"
        :class="{
          'tw-mb-4': !!offer.load.description && !noDescription
        }"
      />

      <!-- Loading description -->
      <div
        v-if="offer.load.description && !noDescription"
        class="ctk-goods-infos__content__description goods-description"
        data-test="description"
      >
        <span
          class="tw-flex tw-text-gray-700 tw-mb-1"
          data-test="label"
        >
          {{ $t('comment') | capitalize }}
        </span>

        <p
          :class="[textClass]"
          class="tw-whitespace-pre-wrap tw-italic tw-break-words tw-mb-0"
          data-test="value"
          v-text="offer.load.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import CtkGoodsInfosLoad from './_subs/CtkGoodsInfosLoad/index.vue'

  /**
   * @module component - ctkGoodsInfos
   * @param {object} offer
   * @param {boolean} [onlyOptions=false]
   * @param {boolean} [dark=false]
   */
  export default {
    name: 'CtkGoodsInfos',
    components: {
      CtkGoodsInfosLoad
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      onlyOptions: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      noTitle: {
        type: Boolean,
        default: false
      },
      noDescription: {
        type: Boolean,
        default: false
      },
      textClass: {
        type: String,
        default: 'tw-text-blue-500'
      }
    },
    computed: {
      offerLoad () {
        const { weight, height } = this.offer.load
        return {
          height: height,
          weight: weight
        }
      }
    }
  }
</script>

<style lang="scss">
.ctk-goods-infos .title {
  border-bottom: 1px solid #D1D4D9;
}
.ctk-goods-infos .goods-card.dark {
  background: #F2F2F2;
}
.ctk-goods-infos .ctk-goods-infos-load .ctk-goods-infos-load__value {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
</style>
