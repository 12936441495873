<template>
  <div class="mission-detail-header tw-flex tw-items-center tw-justify-between tw-px-2">
    <div />
    <h3
      class="tw-flex tw-justify-center tw-items-center tw-text-center tw-text-sm tw-font-normal tw-my-auto"
      data-test="title"
      v-text="$t('missions.detail.title.with_ref', { ref: getCurrentMissionReference })"
    />

    <ui-button
      v-b-tooltip.hover
      :title="$t('close') | capitalize"
      class="mission-detail-header__close"
      variant="link"
      type="button"
      data-test="close-button"
      @click="resetCurrentMission"
    >
      <template #left-icon>
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </template>
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  /**
   * @module components - MissionDetailHeader
   */
  export default defineComponent({
    name: 'MissionDetailHeader',
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionReference'
      ])
    },
    methods: {
      ...mapActions('missions', [
        'resetCurrentMission'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .mission-detail-header {
    height: 40px;
    flex: 0 0 40px;

    & > div:first-child {
      width: 40px;
    }
  }

</style>
