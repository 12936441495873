<template>
  <div
    ref="tabInfo"
    class="tab-info-content"
  >
    <follow-up-status
      :mission="getCurrentMission"
    />

    <ctk-btn-file
      id="btn-download-mission-order"
      :url="urlMissionOrder"
      :popover-title="$t('missions.mission_order_title')"
      :file-name="`mission-order-${getCurrentMission.reference}.pdf`"
      class="tw-w-full mb-3 px-2"
    >
      <ui-button
        :rounded="false"
        variant="info"
        outline
        block
        class="tw-w-full"
      >
        {{ $t('missions.download_the_mission_order') | capitalize }}
      </ui-button>
    </ctk-btn-file>

    <div class="tw-p-2">
      <tab-info-references />

      <template
        v-if="!isCancelled"
      >
        <tab-info-client-infos />
        <driver-infos
          :driver="getCurrentMissionDriver"
          :mission="getCurrentMission"
        />
      </template>

      <ctk-address-infos
        id="pickup"
        :offer="getCurrentMission"
        :class="{'mt-2': isCancelled}"
        direction="pickup"
        class="mb-4"
        full-address
      />
      <ctk-address-infos
        id="delivery"
        :offer="getCurrentMission"
        direction="delivery"
        class="mb-4"
        full-address
      />
      <ctk-goods-infos
        :offer="getCurrentMission"
        class="mb-4"
      />
      <h5 class="total-price tw-font-light">
        {{ $t('app.pricing.your_fare_without_tax') | capitalize }}
      </h5>
      <div class="price-container py-2 mb-5 tw-text-center tw-text-blue-500 tw-bg-white tw-rounded">
        <h5 class="m-0">
          {{ getCurrencyFormat((getCurrentMission.billing.price), getCurrentMission.billing.currency) }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkBtnFile from '@/components/CtkBtnFile'
  import CtkAddressInfos from '@/components/CtkAddressInfos'
  import CtkGoodsInfos from '@/components/CtkGoodsInfos'
  import FollowUpStatus from './_subs/FollowUpStatus'
  import DriverInfos from './_subs/DriverInfos'
  import TabInfoReferences from './_subs/TabInfoReferences'
  import TabInfoClientInfos from './_subs/TabInfoClientInfos'

  export default {
    name: 'TabInfo',
    components: {
      CtkBtnFile,
      CtkAddressInfos,
      CtkGoodsInfos,
      FollowUpStatus,
      TabInfoReferences,
      TabInfoClientInfos,
      DriverInfos
    },
    computed: {
      ...mapGetters('auth', ['getCid']),
      ...mapGetters('missions', [
        'getCurrentMissionShipper',
        'getCurrentMission',
        'getCurrentMissionState',
        'getCurrentMissionDriver'
      ]),
      urlMissionOrder () {
        return `v2/companies/${this.getCid}/missions/${this.getCurrentMission.uuid}/mission-order`
      },
      isCancelled () {
        return this.getCurrentMissionState === 'cancelled'
      }
    },
    methods: {
      getCurrencyFormat (number, currencyString) {
        return this.$options.filters.currency(number, currencyString || this.getCurrentMission.billing.currency, this._i18n.locale)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .tab-info-content {
    &::before {
      content: ' ';
      position: absolute;
      width: 35px;
      height: 2px;
      top: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: #CCC;
    }

    .price-layout {
      padding: 0 !important;
    }
  }

</style>
