<template>
  <div
    :class="{
      'should-have-invoices': shouldHaveInvoices
    }"
    class="mission-item-header tw-flex tw-items-center dots-text"
  >
    <template
      v-if="mission.state === 'planned' || mission.state === 'in_progress'"
    >
      <template
        v-if="isLate"
      >
        <ui-ctk-icon
          name="warning"
          class="tw-text-red-500"
          data-test="icon"
        />
        <span data-test="title">
          {{ title }}
        </span>
      </template>
      <i18n
        v-else
        :path="timeLeftBeforePickup.key"
        tag="span"
        data-test="content"
        class="dots-text"
      >
        <template #time>
          <span
            class="tw-text-blue-500 tw-font-medium"
          >
            {{ timeLeftBeforePickup.time }}
          </span>
        </template>
      </i18n>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import timeLeftFromNow from '@/services/TimeLeftFromNow'

  /**
   * @module component - missionItemHeader
   * @param {object} mission
   * @param {boolean} isLate
   */
  export default defineComponent({
    name: 'MissionItemHeader',
    props: {
      isLate: {
        type: Boolean,
        default: false
      },
      mission: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns true if the mission is either cancelled or completed
       * @function shouldHaveInvoices
       * @returns {boolean}
       */
      shouldHaveInvoices () {
        const { state } = this.mission
        const states = ['completed', 'cancelled']
        return states.includes(state)
      },
      /**
       * Returns the appropriate title according to the mission state.
       * @function title
       * @returns {string}
       */
      title () {
        const { pickup, delivery } = this.mission
        const isDeliveryLate = delivery.state === 'late'
        const isPickupLate = pickup.state === 'late'

        const isLimitExpired = this.isCmrPending && isDeliveryLate
        const lateDirection = isPickupLate ? 'pickup' : 'delivery'

        // @ts-ignore
        return isLimitExpired
          ? this.$t('missions.tracking_time_limit_expired')
          : this.isLate
            ? this.$t(`missions.mission_item.late_text.${lateDirection}`)
            : this.timeLeftBeforePickup
      },
      /**
       * Returns true if the CMR is pending.
       * @function isCmrPending
       * @returns {boolean}
       */
      isCmrPending () {
        const { proof_of_delivery: POD } = this.mission
        return POD && POD.state === 'pending'
      },
      /**
       * @function pickupTimeLeft
       */
      timeLeftBeforePickup () {
        /**
         * TODO: Simplify this to remove the timeLeftFromNow usage.
         */
        const { date, start_time: startTime } = this.mission.pickup.time_slot
        const { isInPast, fromNow } = timeLeftFromNow(date, startTime)
        return {
          key: isInPast ? 'missions.pickup_begin_in_x_time_passed' : 'missions.pickup_begin_in_x_time',
          time: fromNow
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .mission-item-header {
    padding: 5px 30px 5px 30px;
    background: #E9E9EA;

    i.ctk-font {
      font-size: 30px;
    }

    &.should-have-invoices {
      padding: 0 !important;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      padding: 5px 10px !important;
    }
  }

</style>
