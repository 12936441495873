<template>
  <div
    id="CtkTimeSlot"
    :class="{
      dark
    }"
    class="ctk-time-slot tw-flex-1 tw-text-center tw-flex tw-items-center"
  >
    <div
      :class="[textClass]"
      class="ctk-time-slot__container tw-font-normal tw-whitespace-normal tw-relative tw-bg-white tw-rounded tw-text-center tw-w-full 2sm:tw-w-1/2 tw-px-2 tw-mr-4 date tw-flex tw-items-center tw-justify-between"
      data-test="date"
    >
      <div
        class="tw-text-gray-600 tw-text-xs tw-flex tw-items-center"
        data-test="label"
        v-text="label"
      />
      <div
        class="tw-ml-2 vertical-date tw-flex-1"
        data-test="value"
        v-text="timeSlotDate"
      />
    </div>
    <ctk-time-slot-range
      :class="[textClass]"
      :start-time="startTime"
      :end-time="endTime"
      class="ctk-time-slot__container tw-bg-white tw-rounded 2sm:tw-w-1/2 tw-px-2 hours"
      data-test="hours"
    />
  </div>
</template>

<script>
  import CtkTimeSlotRange from './_subs/CtkTimeSlotRange/index.vue'

  export default {
    name: 'CtkTimeSlot',
    components: {
      CtkTimeSlotRange
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      dark: {
        type: Boolean,
        default: false
      },
      direction: {
        type: String,
        required: true
      },
      textClass: {
        type: String,
        default: 'tw-text-blue-500'
      }
    },
    computed: {
      /**
       * Returns the label according to the direction and if the timeslot is flexible or not
       * @function label
       * @returns {string}
       */
      label () {
        const isPickup = this.direction === 'pickup'
        const isFlexible = this.item[this.direction].time_slot.flexible === true
        const flexibleLabel = isPickup ? this.$t('from_the') : this.$t('until')

        return isFlexible
          ? flexibleLabel
          : this.$t('only_on_the')
      },
      startTime () {
        const { date, start_time: startTime } = this.item[this.direction].time_slot
        return this.$moment(`${date} ${startTime}`).format('LT')
      },
      endTime () {
        const { date, end_time: endTime } = this.item[this.direction].time_slot
        return this.$moment(`${date} ${endTime}`).format('LT')
      },
      timeSlotDate () {
        return this.$moment(this.item[this.direction].time_slot.date).format('ll')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-time-slot {
    @media only screen and (max-width: $breakpoint-mobile-s) {
      flex-direction: column;
    }

    &__container {
      height: 40px;
      min-width: 120px;

      &.date,
      &.hours {
        line-height: 16px;
      }

      .vertical-date {
        word-spacing: 9999px;
        text-transform: capitalize;
      }

      @media only screen and (max-width: $breakpoint-mobile-s) {
        &.date,
        &.hours {
          margin: 0 !important;
        }

        &:first-child {
          margin-bottom: 16px !important;
        }
      }
    }

    &.dark .ctk-time-slot__container {
      background: #E9E9EA;
    }
  }

</style>
