<template>
  <div class="ctk-address-reference">
    <div
      v-text="$t('app.labels.reference')"
      data-test="label"
      class="tw-text-gray-700"
    />
    <p
      v-text="reference"
      data-test="value"
      class="ctk-address-reference__content tw-break-words tw-whitespace-pre-line"
    />
  </div>
</template>

<script>
  /**
   * @module component - CtkAddressReference
   * @param {object} offer
   */
  export default {
    name: 'CtkAddressReference',
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    computed: {
      reference () {
        const shipperReference = this.offer.shipment && this.offer.shipment.shipper_reference
        return this.offer[this.direction].reference || shipperReference || null
      }
    }
  }
</script>
