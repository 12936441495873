<template>
  <div
    id="ctkAddressInfos"
    class="ctk-address-infos"
    :class="direction"
  >
    <div class="title-container">
      <h5
        class="ctk-address-infos__title tw-flex tw-items-center tw-mb-0 tw--ml-2"
        data-test="title"
      >
        <ui-ctk-icon
          :class="[
            direction === 'pickup'
              ? 'tw-text-blue-500'
              : 'tw-text-green-500'
          ]"
          class="ctk-address-infos__title__icon tw-text-4xl"
          name="marker"
          data-test="marker"
        />
        <div
          class="ctk-address-infos__title__content tw-font-light tw--ml-1"
          v-text="$options.filters.capitalize(
            direction === 'delivery'
              ? $t('delivery')
              : $t('pickup')
          )"
        />
      </h5>
    </div>

    <div
      :class="[onlyOptions ? 'p-3' : 'px-2 py-3', {'dark': dark}]"
      class="address-card mb-3"
    >
      <div
        v-if="!onlyOptions"
        class="tw-flex tw-items-center mb-3 address-name"
      >
        <span
          v-if="fullAddress"
          class="address-block"
        >
          <span
            v-if="getDirection.address.name"
            class="tw-font-medium"
          >
            {{ getDirection.address.name }}
          </span>
          <br>
          <ctk-address
            :address="getDirection.address"
          />
        </span>
        <ctk-address
          v-else
          :address="getDirection.address"
        >
          <template
            #country
          >
            <span>
              - {{ $t(getDirection.address.country) }}
            </span>
          </template>
        </ctk-address>
      </div>

      <ctk-address-accessibility
        v-if="!!getDirection.address.accessibility && getDirection.address.accessibility.vehicle_type.key !== 'standard_trailer'"
        :vehicle-type="getDirection.address.accessibility.vehicle_type.key"
        :class="{
          'only-options': onlyOptions
        }"
        class="tw-mb-4"
      />

      <ctk-time-slot
        v-if="!onlyOptions"
        :item="offer"
        :direction="direction"
        dark
        class="mt-3 mb-3"
      />

      <div
        v-if="!onlyOptions || (onlyOptions && handlingByDriver)"
        :class="{'mb-1': (onlyOptions && handlingByDriver && hatchNeeded) || !onlyOptions}"
        class="tw-flex tw-flex-row tw-justify-between"
        data-test="handling-by-driver"
      >
        <div
          v-text="$t('handling_by_the_driver')"
          class="tw-flex tw-items-center tw-text-gray-700"
          data-test="label"
        />
        <div
          class="tw-flex tw-items-center tw-text-blue-500 tw-font-normal handling"
          data-test="value"
        >
          {{ handlingByDriver ? $t('yes') : $t('no') | uppercase }}
        </div>
      </div>

      <div
        v-if="!onlyOptions || (onlyOptions && hatchNeeded)"
        class="tw-flex tw-flex-row tw-justify-between"
        data-test="hatch-needed"
      >
        <div
          v-text="$t('need_hatch')"
          class="tw-flex tw-items-center tw-text-gray-700"
          data-test="label"
        />
        <div
          class="tw-flex tw-items-center tw-text-blue-500 tw-font-normal hatch"
          data-test="value"
        >
          {{ hatchNeeded ? $t('yes') : $t('no') | uppercase }}
        </div>
      </div>

      <ctk-address-contact
        v-if="hasContactInformations"
        :contact="contact"
        class="tw-mt-4"
        data-test="contact"
      />

      <ctk-address-reference
        v-if="hasReference"
        :offer="offer"
        :direction="direction"
        class="tw-mt-4"
        data-test="reference"
      />

      <div
        v-if="getDirection.comment"
        :class="{'mt-3': !onlyOptions || onlyOptions && (hatchNeeded || handlingByDriver)}"
        data-test="comment"
      >
        <div
          class="tw-text-gray-700 tw-mb-1"
          data-test="label"
        >
          {{ $t('comment')|capitalize }}
        </div>
        <p
          class="tw-text-blue-500 tw-break-words tw-italic tw-mb-0 address-comment"
          v-text="getDirection.comment"
          data-test="content"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'
  import CtkAddress from '@/components/CtkAddress/index.vue'
  import CtkTimeSlot from '@/components/CtkTimeSlot/index.vue'
  import CtkAddressAccessibility from './_subs/CtkAddressAccessibility/index.vue'
  import CtkAddressContact from '@/components/CtkAddressInfos/_subs/CtkAddressContact/index.vue'
  import CtkAddressReference from '@/components/CtkAddressInfos/_subs/CtkAddressReference/index.vue'

  export default defineComponent({
    name: 'CtkAddressInfos',
    components: {
      UiCtkIcon,
      CtkTimeSlot,
      CtkAddress,
      CtkAddressContact,
      CtkAddressReference,
      CtkAddressAccessibility
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      onlyOptions: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      fullAddress: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { offer, direction } = toRefs(props)

      const getDirection = computed(() => offer.value[direction.value])
      const contact = computed(() => getDirection.value.contact)

      /**
       * Returns true if there is either a name or a phone number in the
       * contact informations
       */
      const hasContactInformations = computed(() => {
        return contact.value && (!!contact.value.name || !!contact.value.phone)
      })

      /**
       * Returns true if there is either the step reference or the shipper reference
       */
      const hasReference = computed(() => {
        const shipperReference = offer.value.shipment && offer.value.shipment.shipper_reference
        return !!getDirection.value.reference || !!shipperReference || false
      })

      const handlingByDriver = computed(() => getDirection.value.handling.driver)
      const hatchNeeded = computed(() => getDirection.value.handling.tail_lift)

      return {
        contact,
        hasReference,
        getDirection,
        handlingByDriver,
        hatchNeeded,
        hasContactInformations
      }
    }
  })
</script>

<style lang="scss">
.ctk-address-infos .info-container {
  background-color: #E9E9EA;
  font-weight: 400;
  border-radius: 4px;
  text-align: center;
  height: 44px;
}
.ctk-address-infos .info-container.date, .ctk-address-infos .info-container.hours, .ctk-address-infos .info-container.price {
  line-height: 16px;
}
.ctk-address-infos .address-card {
  background-color: #FFF;
  border-radius: 4px;
  position: relative;
}
.ctk-address-infos .address-card.dark {
  background-color: $light-gray;
}
.ctk-address-infos .address-card .ctk-address-accessibility:not(.only-options) {
  position: relative;
}
.ctk-address-infos .address-card .ctk-address-accessibility:not(.only-options)::before {
  content: '';
  position: absolute;
  top: -7.5px;
  left: 0.1rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 8px 7.5px;
  border-color: transparent transparent #E2E8F0 transparent;
}
.ctk-address-infos .ctk-address-contact .ctk-address-contact__content, .ctk-address-infos .ctk-address-reference .ctk-address-reference__content {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
  font-style: italic;
}
</style>
