<template>
  <ctk-item-cancellation-date
    :label="$tc('missions.state.cancelled', 1) | capitalize"
    class="mission-item-cancellation-date tw-flex-1 tw-flex tw-flex-row tw-items-center"
  >
    {{ cancelledAt }}
  </ctk-item-cancellation-date>
</template>

<script>
  import CtkItemCancellationDate from '@/components/CtkItem/CtkItemCancellationDate'

  /**
   * @module component - missionItemCancellationDate
   * @param {object} mission
   */
  export default {
    name: 'MissionItemCancellationDate',
    components: {
      CtkItemCancellationDate
    },
    props: {
      mission: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns the formated cancelled_at date
       * @function cancelledAt
       * @returns {string}
       */
      cancelledAt () {
        return this.$moment(this.mission.cancelled_at).format('lll')
      }
    }
  }
</script>

<style lang="scss">

  .mission-item-cancellation-date {
    min-width: 120px;

    @media only screen and (max-width: $breakpoint-mobile-l) {
      text-align: left !important;

      .ctk-item-cancellation-date__label {
        justify-content: flex-start !important;
      }
    }
  }

</style>
