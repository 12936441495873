<template>
  <ui-button
    :variant="active ? 'info' : 'link'"
    class="ctk-list-filters-items-item px-3 dots-text"
    @click="click"
  >
    <div class="tw-flex tw-items-center">
      <ui-material-icon
        :name="active
          ? multiple
            ? 'check_box'
            : 'radio_button_checked'
          : multiple
            ? 'check_box_outline_blank'
            : 'radio_button_unchecked'"
        class="mr-1"
      />
      <span class="dots-text">
        <slot />
      </span>
    </div>
  </ui-button>
</template>

<script>
  /**
   * @module component - CtkListFiltersItemsItem
   * @param {boolean} active
   * @param {boolean} [multiple=true]
   */
  export default {
    name: 'CtkListFiltersItemsItem',
    props: {
      active: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      click () {
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-list-filters-items-item .material-icons {
    font-size: 18px;
  }

</style>
