<template>
  <div
    id="MissionDetailMap"
    :style="{ height }"
    class="mission-detail-map flex-fixed"
  >
    <l-map
      ref="map"
      :zoom="mapData.zoom"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="mapData.options"
      :center="mapData.center"
      :style="{ height }"
      class="leaflet-map"
    >
      <l-control-zoom :position="mapData.controlZoomPosition" />
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />
      <l-feature-group ref="markers">
        <l-marker
          ref="pickup"
          :lat-lng="mission.pickup.address.location"
          :icon="getPickupIcon"
        >
          <l-popup
            :content="formatPopup(mission.pickup.address, 'pickup')"
            :options="popupOptions"
          />
        </l-marker>
        <l-marker
          ref="delivery"
          :lat-lng="mission.delivery.address.location"
          :icon="getDeliveryIcon"
        >
          <l-popup
            :content="formatPopup(mission.delivery.address, 'delivery')"
            :options="popupOptions"
          />
        </l-marker>
      </l-feature-group>

      <l-polyline
        v-if="itinerary && !!itinerary.latlngs"
        :visible="itinerary.visible"
        :lat-lngs="itinerary.latlngs"
        :weight="itinerary.style.weight"
        :color="itinerary.style.color"
        :opacity="itinerary.style.opacity"
        :fill="false"
      />
    </l-map>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import {
    LMap,
    LMarker,
    LTileLayer,
    LFeatureGroup,
    LPolyline,
    LPopup,
    LControlZoom
  } from 'vue2-leaflet'

  import { icons, fitBoundsOptions } from '@/services/Leaflet'

  export default {
    name: 'MissionDetailMap',
    components: {
      LMap,
      LMarker,
      LTileLayer,
      LFeatureGroup,
      LPolyline,
      LPopup,
      LControlZoom
    },
    props: {
      mission: {
        type: Object,
        default: null
      },
      height: {
        type: String,
        required: true
      },
      directionFocus: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        mapData: {
          zoom: 4,
          maxZoom: 14,
          minZoom: 2,
          options: {
            zoomControl: false,
            keyboard: false,
            scrollWheelZoom: false
          },
          controlZoomPosition: 'topright',
          center: [48.8664982, 2.3348235],
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
          url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
        },
        itinerary: {
          timeout: null,
          visible: false,
          latlngs: null,
          style: {
            color: '#287696',
            opacity: 1,
            weight: 3
          }
        },
        L: null,
        line: null,
        markers: [],
        popupOptions: {
          closeButton: false,
          autoClose: false
        }
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getMissionsItineraries',
        'getCurrentMission'
      ]),
      getPickupIcon () {
        return icons.pickup('in_progress')
      },
      getDeliveryIcon () {
        return icons.delivery('in_progress')
      },
      /**
       * Returns the flyTo & flyToBounds options.
       * @function flyOptions
       */
      flyOptions () {
        return {
          animate: true,
          padding: [32, 32],
          duration: 0.8
        }
      },
      /**
       * @function isInformationTab
       */
      isInformationTab () {
        return this.directionFocus === 'travel'
      },
      /**
       * @function isPickupCompleted
       */
      isPickupCompleted () {
        return this.mission.pickup.state === 'completed'
      },
      /**
       * @function isDeliveryCompleted
       */
      isDeliveryCompleted () {
        return this.mission.delivery.state === 'completed'
      },
      hasMapObject () {
        return this.$refs.map && this.$refs.map.mapObject
      }
    },
    watch: {
      mission: {
        handler (newValue) {
          this.retrieveItinerary(newValue)
          if (this.hasMapObject) this.$refs.map.mapObject.closePopup()
          this.$nextTick(() => {
            this.zoomToMarkers()
          })
        },
        deep: true
      },
      directionFocus (newValue) {
        if (newValue === 'travel') {
          this.retrieveItinerary(this.mission)
          if (this.hasMapObject) this.$refs.map.mapObject.closePopup()
          this.$nextTick(() => {
            this.zoomToMarkers()
          })
        } else {
          if (this.hasMapObject) this.$refs.map.mapObject.closePopup()
          this.$nextTick(() => {
            this.zoomToMarkers()
          })
        }
      }
    },
    created () {
      this.L = window.L
      setTimeout(() => {
        if (this.hasMapObject) {
          this.$refs.map.mapObject.invalidateSize()
          this.$nextTick(() => {
            this.retrieveItinerary(this.mission)
            this.$nextTick(() => {
              this.zoomToMarkers()
            })
          })
        }
      }, 300)
    },
    methods: {
      ...mapActions('missions', ['getMissionTravel', 'pushMissionItinerary']),
      formatPopup (address, direction) {
        const comment = `
          <div class="content tw-text-gray-700">
            ${address.comment}
          </div>
        `
        return `
          <div class="header ${direction}">
            <b>${address.name}</b><br>
            ${address.street_name} - ${address.postal_code}<br>
            ${address.city}, ${this.$t(address.country)}
          </div>
          ${address.comment ? comment : ''}
        `
      },
      /**
       * Zoom to a single marker
       * @function zoomToMarker
       * @param {string} markerRef
       */
      zoomToMarker (markerRef) {
        if (this.hasMapObject) {
          try {
            const map = this.$refs.map.mapObject
            const marker = this.$refs[markerRef].mapObject

            if (map && marker) {
              map.flyTo(marker.getLatLng(), 10, this.flyOptions)

              this.itinerary.timeout = setTimeout(() => {
                this.itinerary.visible = true
              }, fitBoundsOptions.duration * 1000)

              setTimeout(() => {
                marker.openPopup()
              }, 900)
            }
          } catch (e) {
            console.error('Could not zoom to the marker.', e)
          }
        }
      },
      zoomToMarkers () {
        if (
          this.isInformationTab ||
          (!this.isInformationTab && this.isPickupCompleted && this.isDeliveryCompleted)
        ) {
          /**
           * Zoom to the marker group
           */
          if (this.hasMapObject) {
            try {
              const map = this.$refs.map.mapObject
              const markers = this.$refs.markers.mapObject
              if (map && markers) {
                map.flyToBounds(markers.getBounds(), this.flyOptions)

                this.itinerary.timeout = setTimeout(() => {
                  this.itinerary.visible = true
                }, fitBoundsOptions.duration * 1000)

                /*
                * Open popup only if i have 1 marker
                */
                if (!this.isInformationTab && (!this.isPickupCompleted || !this.isDeliveryCompleted)) {
                  const marker = !this.isPickupCompleted
                    ? this.$refs.pickup.mapObject
                    : this.$refs.delivery.mapObject

                  setTimeout(() => {
                    marker.openPopup()
                  }, 900)
                }
              }
            } catch (e) {
              console.error('Could not zoom to the marker.', e)
            }
          }
        } else if (!this.isPickupCompleted || (this.isPickupCompleted && this.isDeliveryCompleted)) {
          this.zoomToMarker('pickup')
        } else if (this.isPickupCompleted) {
          this.zoomToMarker('delivery')
        }
      },
      /**
       * Remove the line itinerary from the map
       * @function removeLine
       */
      removeLine () {
        this.itinerary.visible = false
        this.itinerary.latlngs = null
        if (this.itinerary.timeout) {
          clearTimeout(this.itinerary.timeout)
          this.itinerary.timeout = null
        }
      },
      retrieveItinerary (mission) {
        this.removeLine()

        const exists = this.getMissionsItineraries.find(e => e.uuid === mission.uuid)
        if (exists) {
          this.itinerary.latlngs = exists.itinerary.coordinates
            .map(location => ([
              location[1],
              location[0]
            ]))
        } else {
          this.removeLine()
          this.getMissionTravel()
            .then((res) => {
              if (res.data.itinerary) {
                this.pushMissionItinerary({
                  uuid: mission.uuid,
                  itinerary: res.data.itinerary
                })

                /**
                 * Force the itinerary change only if the offer didn't change during
                 * the request.
                 */
                if (this.getCurrentMission.uuid === mission.uuid) {
                  this.itinerary.latlngs = res.data.itinerary.coordinates
                    .map(location => ([
                      location[1],
                      location[0]
                    ]))
                }
              }
            })
            .catch((err) => {
              console.log('Error occured while retrieving this itinerary', err)
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .mission-detail-map {
    position: relative;

    .leaflet-map {
      background: #EAEAEA;
      width: 100%;
      height: 100%;
      min-height: 200px;
      z-index: 0;
    }
  }

</style>
