<template>
  <div class="tab-info-client-infos">
    <h5
      v-text="$t('missions.mission_detail.label.client')"
      class="tw-font-light"
      data-test="title"
    />

    <div class="tw-bg-white tw-rounded tw-p-2 tw-mb-4 tw-truncate">
      <div
        class="tw-font-medium tw-truncate"
        data-test="name"
      >
        {{ getCurrentMissionShipper.name }} / {{ getCurrentMissionShipper.contact }}
      </div>
      <p
        class="tw-text-gray-600 tw-m-0"
        data-test="phone"
      >
        {{ getCurrentMissionShipper.phone | telephone }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * @module component - TabInfoClientInfos
   */
  export default {
    name: 'TabInfoClientInfos',
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionShipper'
      ])
    }
  }
</script>
