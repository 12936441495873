<template>
  <div class="direction-step p-2 tw-flex-1 tw-flex tw-flex-col tw-justify-between">
    <ctk-time-slot
      :item="getCurrentMission"
      :direction="direction"
      class="mb-3"
      dark
    />

    <ctk-handling-infos
      :item="getCurrentMission"
      :direction="direction"
    />

    <!-- Confirm -->
    <ui-button
      :loading="$wait.is('loading direction step')"
      :disabled="$wait.is('loading direction step')"
      variant="primary"
      type="button"
      class="direction-step__button"
      @click="nextStep"
    >
      {{ $t((getCurrentMissionPickupState === 'pending' || getCurrentMissionPickupState === 'late')
        ? 'missions.pickup_done'
        : 'missions.delivery_done'
      ) }}
    </ui-button>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import CtkTimeSlot from '@/components/CtkTimeSlot'
  import CtkHandlingInfos from '@/components/CtkHandlingInfos'

  /**
   * Component used to show a step for the pickup and the delivery.
   * The direction should be specified in the props.
   * @module component - directionStep
   * @param {string} direction - Step direction, may be pickup or delivery.
   */
  export default {
    name: 'DirectionStep',
    components: {
      CtkTimeSlot,
      CtkHandlingInfos
    },
    props: {
      direction: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMission',
        'getCurrentMissionPickupState'
      ])
    },
    methods: {
      ...mapActions('missions', [
        'companyMissionActions'
      ]),
      /**
       * @function nextStep
       */
      nextStep () {
        this.$wait.start('loading direction step')
        this.companyMissionActions()
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              const errorMessage = data.error.detail || data.error.title
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            this.$wait.end('loading direction step')
          })
      }
    }
  }
</script>
