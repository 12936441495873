<template>
  <div class="tab-billing">
    <div
      v-if="getBilling"
      class="p-2 py-4 billing-container"
    >
      <billing-infos
        v-if="hasInvoiceNotPaid || !hasMissionCompleted"
        :billing="getBilling"
      />
      <pricing-total
        v-if="!getBilling.invoices"
        id="no-invoices"
        :label="$t('missions.your_fare')"
        :primary-value="$t('missions.price_excl_tax', {price: getCurrencyFormat(getBilling.price)})"
        :without-arrow="typeof getBilling.lines === 'undefined' || !getBilling.lines.length"
        class="mb-4 p-3"
      />

      <pricing-total
        v-if="hasMultiInvoices"
        id="multi-invoices"
        :label="$t('missions.your_fare')"
        :secondary-value="getCurrencyFormat(getBilling.invoices_amount)"
        :primary-value="$t('missions.price_incl_tax', {price: getCurrencyFormat(getBilling.invoices_vat_included_amount)})"
        class="mb-4"
        blue
      />

      <pricing-invoices-title
        v-if="hasMultiInvoices"
        :count="invoiceLength"
      />
      <!-- <div
        class="tw-flex tw-justify-between tw-items-center mb-2 invoices-title-container">
        <h5
          class="tw-font-normal m-0"
        >
          {{ $t('missions.associated_invoices') }}
        </h5>
        <span class="tw-text-white tw-rounded pl-3 pr-3 tw-flex tw-items-center tw-bg-blue-500 tw-justify-center tw-font-medium">{{ invoiceLength }}</span>
      </div> -->

      <pricing-invoice
        v-for="invoice in getBilling.invoices"
        :key="invoice.uuid"
        :invoice="invoice"
        :currency="getBilling.currency"
        :class="`${invoice.reference}`"
      />

      <pricing-layout
        v-if="!getBilling.invoices"
        class="mb-3"
      >
        <pricing-lines
          v-for="(priceComponent, k) in getBilling.lines"
          :key="priceComponent.key + k"
          :label="priceComponent.label"
          :comment="priceComponent.comment"
          :cancelled="priceComponent.is_cancelled"
        >
          <span class="tw-text-blue-500">
            {{ getCurrencyFormat(priceComponent.price) }}
          </span>
        </pricing-lines>
      </pricing-layout>
    </div>

    <div
      v-else
      class="load-layer"
    >
      <ui-loader
        :size="40"
        background-back="#000"
        type="pallet"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import PricingTotal from '@/components/CtkPricing/_subs/PricingTotal'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines'
  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout'
  import PricingInvoice from '@/components/CtkPricing/_subs/PricingInvoice'
  import PricingInvoicesTitle from '@/components/CtkPricing/_subs/PricingInvoicesTitle'
  import BillingInfos from './_subs/BillingInfos'

  export default {
    name: 'TabBilling',
    components: {
      PricingTotal,
      PricingLines,
      PricingLayout,
      PricingInvoice,
      PricingInvoicesTitle,
      BillingInfos
    },
    props: {
      mission: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionStatus', 'getBilling'
      ]),
      ...mapGetters('auth', ['getCid']),
      invoiceLength () {
        return this.getBilling.invoices && this.getBilling.invoices.length
      },
      hasMultiInvoices () {
        return this.invoiceLength > 1
      },
      hasInvoiceNotPaid () {
        let response = false
        if (this.invoiceLength) {
          this.getBilling.invoices.forEach(invoice => {
            if (!invoice.payment) {
              response = true
            }
          })
        }
        return response
      },
      hasMissionCompleted () {
        return this.getCurrentMissionStatus === 'completed'
      }
    },
    watch: {
      mission () {
        this.getCurrentMissionBilling()
      }
    },
    created () {
      this.getCurrentMissionBilling()
    },
    methods: {
      ...mapActions('missions', [
        'getCurrentMissionBilling'
      ]),
      getCurrencyFormat (number, currencyString) {
        return this.$options.filters.currency(number, currencyString || this.getBilling.currency, this._i18n.locale)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .tab-billing {
    .info-container {
      position: relative;

      &.main::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 15px;
        right: 0;
        width: 0;
        height: 0;
        border-top: solid 15px #FFF;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
      }
    }

    .load-layer {
      background: #F2F2F2;
      min-height: 300px;
      position: relative;
    }
  }

</style>
