<template>
  <div class="tw-flex ctk-list-filters">
    <div
      v-text="label"
      class="ctk-list-filters__label mr-2 tw-text-gray-700"
    />
    <ctk-list-filters-items>
      <ctk-list-filters-items-item
        v-for="(filter, k) in filters"
        :key="k"
        :active="isFilterActive(k)"
        :multiple="multiple"
        @click="toggle(k, filter)"
      >
        {{ filter }}
      </ctk-list-filters-items-item>
    </ctk-list-filters-items>

    <ctk-list-filters-select
      id="filters"
      v-model="filtersSelected"
      :filters="mappedFilters"
      :multiple="multiple"
      :has-dot="hasDot"
    />

    <!-- Mobile -->
    <ui-button
      class="ctk-list-filters__mobile__btn tw-w-full"
      variant="info"
      @click="isMenuOpen = true"
    >
      {{ dialogTitle }}
      <span
        v-if="activeFiltersCount"
        v-text="`(${activeFiltersCount})`"
        data-test="active-filters"
      />
    </ui-button>
    <transition name="fade">
      <div
        v-show="isMenuOpen"
        class="ctk-list-filters__mobile tw-absolute tw-p-0 tw-border-none tw-w-full tw-h-screen tw-bg-white"
      >
        <div class="tw-flex tw-justify-between ctk-list-filters__mobile__header px-3">
          <div
            v-text="dialogTitle"
            class="ctk-list-filters__mobile__header__title"
          />
          <ui-button
            :title="$t('close') | capitalize"
            type="button"
            class="ctk-list-filters__mobile__header__close"
            variant="link"
            @click="isMenuOpen = false"
          >
            <template #left-icon>
              <ui-ctk-icon
                name="close"
                data-test="icon"
              />
            </template>
          </ui-button>
        </div>
        <div class="ctk-list-filters__mobile__content tw-flex tw-flex-col">
          <ui-button
            v-for="(filter, k) in filters"
            :key="k"
            :variant="isFilterActive(k) ? 'info' : 'link'"
            class="ctk-list-filters__mobile__content__item ctk-list-filters__items__item px-3 tw-w-full"
            @click="toggle(k)"
          >
            <div class="tw-flex tw-justify-between">
              <span
                v-text="filter"
              />
              <ui-material-icon
                :name="isFilterActive(k)
                  ? multiple
                    ? 'check_box'
                    : 'radio_button_checked'
                  : multiple
                    ? 'check_box_outline_blank'
                    : 'radio_button_unchecked'"
                class="mr-1"
                data-test="checkbox"
              />
            </div>
          </ui-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkListFiltersItems from './_subs/CtkListFiltersItems/index.vue'
  import CtkListFiltersItemsItem from './_subs/CtkListFiltersItems/_subs/CtkListFiltersItemsItem/index.vue'
  import CtkListFiltersSelect from './_subs/CtkListFiltersSelect/index.vue'

  /**
   * @module component - CtkListFilters
   * @param {string} label - Label used in-front of the buttons & in the mobile dialog title
   * @param {object} filters - A key-value based object with the filters
   * @param {boolean} hasDot
   * @param {boolean} multiple
   */
  export default defineComponent({
    name: 'CtkListFilters',
    components: {
      CtkListFiltersItems,
      CtkListFiltersSelect,
      CtkListFiltersItemsItem
    },
    props: {
      label: {
        type: String,
        required: true
      },
      dialogTitle: {
        type: String,
        required: true
      },
      filters: {
        type: Object,
        required: true
      },
      value: {
        type: Array,
        required: true
      },
      hasDot: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isMenuOpen: false
      }
    },
    computed: {
      filtersSelected: {
        get () {
          return this.value || []
        },
        set (v) {
          this.$emit('input', v)
        }
      },
      /**
       * Returns the amount of active filters
       * @function activeFiltersCount
       * @returns {number}
       */
      activeFiltersCount () {
        return this.filtersSelected.length
      },
      mappedFilters () {
        return Object.keys(this.filters)
          .map(key => ({
            value: key,
            text: this.filters[key]
          }))
      }
    },
    methods: {
      /**
       * Returns true if the filter is active
       * @function isFilterActive
       * @param {string} filter
       * @return {boolean}
       */
      isFilterActive (filter) {
        return !!this.filtersSelected.find(f => f.value === filter)
      },
      /**
       * Add or remove the filter element from the selected values.
       * @function toggle
       */
      toggle (filter, text) {
        const isActive = this.isFilterActive(filter)
        if (!this.multiple) {
          this.filtersSelected = [{
            value: filter,
            text
          }]
        } else {
          if (isActive) {
            const filterIndex = this.filtersSelected.findIndex(f => f.value === filter)
            const filtersSelectedClone = [...this.filtersSelected]
            filtersSelectedClone.splice(filterIndex, 1)
            this.filtersSelected = filtersSelectedClone
          } else {
            const filtersSelectedClone = [...this.filtersSelected]
            filtersSelectedClone.push({
              value: filter,
              text
            })
            this.filtersSelected = filtersSelectedClone
          }
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-list-filters {
    &,
    & .ctk-list-filters__label {
      margin: auto 0;
    }

    .ctk-list-filters-items-item {
      &:not(:last-child) {
        margin-right: 16px;
      }

      .material-icons {
        vertical-align: middle;
        font-size: 19px;
        margin: auto 0;
        opacity: 0.8;
      }

      &.btn-white {
        border: 1px solid $gray;
        color: $secondary-text;
      }

      &.btn-info {
        .material-icons {
          opacity: 1;
        }
      }
    }

    .ctk-list-filters__mobile {
      left: 0;
      top: 0;
      z-index: 100;

      &__btn {
        display: none;
        white-space: nowrap;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        &,
        &__btn {
          display: block;
        }
      }

      &__header {
        min-height: 80px;

        &__title,
        &__close {
          margin: auto 0;
        }

        &__title {
          font-size: 18px;
        }
      }

      &__content__item {
        min-height: 45px;
        border-radius: 0;

        &:not(:first-child) {
          border-top: none;
        }
      }
    }

    .ctk-list-filters__label {
      white-space: nowrap;
    }

    .ctk-list-filters-items,
    .ctk-list-filters__label {
      display: none;
    }

    .ctk-list-filters-select {
      @media only screen and (max-width: $breakpoint-mobile-l) {
        display: none;
      }
    }
  }

</style>
