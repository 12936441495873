<template>
  <div class="tab-steps tw-flex tw-flex-col">
    <ctk-stepper-bar
      :steps="stepsBar"
      :current-step="currentStep"
    />

    <not-available
      v-if="getCurrentMissionState === 'planned'"
      :mission="getCurrentMission"
    />

    <direction-step
      v-else-if="getCurrentMissionState === 'in_progress' && getCurrentMissionDeliveryState !== 'completed'"
      :direction="(getCurrentMissionPickupState === 'pending' || getCurrentMissionPickupState === 'late') ? 'pickup' : 'delivery'"
    />
  </div>
</template>

<script>
  import CtkStepperBar from '@/components/CtkStepperBar'
  import { mapGetters, mapActions } from 'vuex'

  import NotAvailable from './_subs/NotAvailable'
  import DirectionStep from './_subs/DirectionStep'

  export default {
    name: 'TabSteps',
    components: {
      CtkStepperBar,
      NotAvailable,
      DirectionStep
    },
    data () {
      return {
        stepsBar: [
          this.$t('pickup'),
          this.$t('delivery')
        ]
      }
    },
    computed: {
      ...mapGetters('missions', [
        'getCurrentMissionPickupState',
        'getCurrentMissionDeliveryState',
        'getCurrentMissionState',
        'getCurrentMission'
      ]),
      ...mapGetters('auth', ['getCid']),
      currentStep () {
        if (this.getCurrentMission.proof_of_delivery && this.getCurrentMission.proof_of_delivery.state !== 'pending') {
          return 3
        } else if (this.getCurrentMissionPickupState === 'pending' || this.getCurrentMissionPickupState === 'late') {
          return 0
        } else if (this.getCurrentMissionPickupState === 'completed' && (this.getCurrentMissionDeliveryState === 'pending' || this.getCurrentMissionDeliveryState === 'late')) {
          return 1
        } else {
          return 2
        }
      }
    },
    updated () {
      if (this.$refs.CtkDropzone && this.$refs.CtkDropzone.$refs.vueDropzone) {
        this.$refs.CtkDropzone.$refs.vueDropzone.removeAllFiles()
      }
    },
    methods: {
      ...mapActions('missions', [
        'updateProofOfDelivery'
      ])
    }
  }
</script>
